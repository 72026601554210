// This is the code for the Navigation Bar for the Sign In & Sign Up Page
// The CSS for this page is located in src/containers/includes/css/style.css

import React, { Component, Fragment } from 'react';
import { Container, connect, CircularProgress, ToastContainer, Logo, Row, Col } from '../components';
import SignIn from './SignIn';
import SignUp from './SignUp';
import ForgetPassword from './forgetpassword';

class Auth extends Component {
  constructor(props) {
    super(props);
    this.state = {
      form: 'login',
    };
  }

  componentDidMount() {
    if (this.props.authUser !== null) {
      this.props.history.push('/app/dashboard/index');
    }
  }
  changeView = (view) => {
    this.setState({ form: view });
  };

  render() {
    const { form, newPassword } = this.state;

    return (
      <Container fluid={true} className="auth-container">
        <Row className="sidebars">
          <Col md="4" xs="12" className="left-sidebar">
            <Row>
              {/* MedsOnWheels Logo */}
              <Col md="12" className="logo-div" onClick={() => this.changeView('login')}>
                <img src={require('assets/images/logo.png')} className="logo-login" />
              </Col>

              {/* Sign In Link */}
              <Col md="12" onClick={() => this.changeView('login')} className={'p-0 m-0'}>
                <h3 className={`auth-tab ${form === 'login' || form === 'forgot' ? 'active' : ''}`}>Sign In</h3>
              </Col>

              {/* Sign Up Link */}
              <Col md="12" onClick={() => this.changeView('signup')} className={'p-0 m-0'}>
                <h3 className={`auth-tab ${form === 'signup' ? 'active' : ''}`}>Sign Up</h3>
              </Col>
            </Row>
          </Col>
          <Col md="8" xs="12">
            {form === 'login' ? (
              <SignIn changeView={this.changeView} />
            ) : form === 'forgot' ? (
              <ForgetPassword changeView={this.changeView} />
            ) : (
              <SignUp />
            )}
            <ToastContainer />
          </Col>
        </Row>
      </Container>
    );
  }
}

const mapStateToProps = ({ auth }) => {
  const { authUser } = auth;
  return { authUser };
};

export default connect(mapStateToProps, null)(Auth);
