import React, { useState } from "react";
import { ConnectedRouter } from "connected-react-router";
import { Provider } from "react-redux";
import { Route, Switch, HashRouter, withRouter } from "react-router-dom";
import { toast } from "react-toastify";
// import { onMessageListener } from "./firebaseInit";
import Notifications from "./components/Notifications/Notifications";
import ReactNotificationComponent from "./components/Notifications/ReactNotification";
import AudioPlayer from "react-h5-audio-player";
import "react-h5-audio-player/lib/styles.css";
import configureStore, { history } from "./store";
import App from "./containers/App";
import { useEffect } from "react";

import Cookies from "js-cookie";

// import { getToken } from "./firebaseInit.js";

import { API_GET_PHARMACY_DETAILS, API_URL, API_VERSION, } from './ApiUrl';
import axios  from 'axios';

export const store = configureStore();

const MainApp = () => {
  const [show, setShow] = useState(false);
  const [isTokenFound, setTokenFound] = useState(false);

  const [notification, setNotification] = useState({
    title: "",
    body: "",
  });

  // useEffect(() => {
  //   async function tokenFunc() {
  //     let data = await getToken(setTokenFound);
  //     if (data) {
  //       console.log("Token is", data);
  //       localStorage.setItem("firebaseToken", data);
  //     }
  //   }

  //   tokenFunc();
  // }, [setTokenFound]);

  useEffect(() => {
    axios.get(API_GET_PHARMACY_DETAILS, {
      baseURL: `${API_URL}${API_VERSION}`,
      headers: {
        token: Cookies.get("token"),
        restaurantid: Cookies.get("_id"),
      },
    }).then((response) => {
      let data = response.data;

      if (data) 
        data = data.data;
        Cookies.set('featureAccess', data.featureAccess);
        Cookies.set("province", data.province);
      }
)  }, []);

  // onMessageListener()
  //   .then((payload) => {
  //     console.log(payload, " im hot");

  //     toast.info(
  //       `${payload.notification.title}
  //       ${payload.notification.body}`
  //     );
  //   })
  //   .catch((err) => console.log("failed: ", err));
  return (
    <Provider store={store}>
      {/* <Notifications /> */}
      <ConnectedRouter history={history}>
        <Switch>
          <HashRouter>
            <Route path="/" component={App} />
          </HashRouter>
        </Switch>
      </ConnectedRouter>
    </Provider>
  );
};

export default MainApp;
