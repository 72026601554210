import React from "react";
import { Route, Switch, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Header from "components/Header/index";
import Sidebar from "containers/SideNav/index";
import Footer from "components/Footer";

import Recurring from "./routes/recurring";
import Dashboard from "./routes/dashboard";
import Orders from "./routes/orders";
import Deliveries from "./routes/deliveries";
import Expenses from "./routes/expenses";
import Patients from "./routes/patients";
import Payments from "./routes/payments";
import Drivers from "./routes/drivers";
import CreateOders from "./routes/createOders";
import Products from "./routes/products";
// import PromoCodes from "./routes/promo-codes";
import Settings from "./routes/settings";
import Notification from "./routes/notification";
import {
  ABOVE_THE_HEADER,
  BELOW_THE_HEADER,
  COLLAPSED_DRAWER,
  FIXED_DRAWER,
  HORIZONTAL_NAVIGATION,
} from "constants/ActionTypes";

import { isIOS, isMobile } from "react-device-detect";
import asyncComponent from "../util/asyncComponent";
import TopNav from "components/TopNav";

import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";

class App extends React.Component {
  render() {
    const {
      match,
      drawerType,
      navigationStyle,
      horizontalNavPosition,
    } = this.props;
    const drawerStyle = drawerType.includes(FIXED_DRAWER)
      ? "fixed-drawer"
      : drawerType.includes(COLLAPSED_DRAWER)
        ? "collapsible-drawer"
        : "mini-drawer";

    //set default height and overflow for iOS mobile Safari 10+ support.
    if (isIOS && isMobile) {
      document.body.classList.add("ios-mobile-view-height");
    } else if (document.body.classList.contains("ios-mobile-view-height")) {
      document.body.classList.remove("ios-mobile-view-height");
    }

    return (
      <div className={`app-container ${drawerStyle}`}>
        <Sidebar />
        <div className="app-main-container">
          <div
            className={`app-header ${navigationStyle === HORIZONTAL_NAVIGATION
                ? "app-header-horizontal"
                : ""
              }`}
          >
            {navigationStyle === HORIZONTAL_NAVIGATION &&
              horizontalNavPosition === ABOVE_THE_HEADER && (
                <TopNav styleName="app-top-header" />
              )}
            <Header />
            {navigationStyle === HORIZONTAL_NAVIGATION &&
              horizontalNavPosition === BELOW_THE_HEADER && <TopNav />}
          </div>

          <main className="app-main-content-wrapper">
            <div className="app-main-content">
              <Switch>
                <Route path={`${match.url}/dashboard`} component={Dashboard} />
                <Route path={`${match.url}/products`} component={Products} />
                <Route path={`${match.url}/orders`} component={Orders} />
                <Route
                  path={`${match.url}/createOrder`}
                  component={CreateOders}
                />
                <Route path={`${match.url}/recurring`} component={Recurring} />
                <Route path={`${match.url}/deliveries`} component={Deliveries} />
                <Route path={`${match.url}/expenses`} component={Expenses} />
                <Route path={`${match.url}/patients`} component={Patients} />

                <Route
                  path={`${match.url}/notifications`}
                  component={Notification}
                />

                <Route path={`${match.url}/payments`} component={Payments} /> 
                <Route path={`${match.url}/drivers`} component={Drivers} />
                {/* <Route path={`${match.url}/promo-codes`} component={PromoCodes} /> */}
                <Route path={`${match.url}/settings`} component={Settings} />
                <Route
                  component={asyncComponent(() =>
                    import("app/routes/extraPages/routes/404")
                  )}
                />
              </Switch>
            </div>
            <Footer />
            <ToastContainer autoClose={2000} />
          </main>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ settings }) => {
  const { drawerType, navigationStyle, horizontalNavPosition } = settings;
  return { drawerType, navigationStyle, horizontalNavPosition };
};
export default withRouter(connect(mapStateToProps)(App));
