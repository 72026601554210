import React from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';

import { API_URL, API_VERSION, API_TOKEN } from '../ApiUrl';

class Axios {
  defaultOptions = () => {
    return {
      baseURL: `${API_URL}${API_VERSION}`,
      headers: {
        token: Cookies.get('token'),
        restaurantid: Cookies.get('_id'),
      },
    };
  };

  LogoutUser = (url) => {
    if (url.response !== undefined && url.response.status === 401) {
      console.log(url.response.status, '401 Unauthorized Api Call');

      Cookies.remove('token');
      Cookies.remove('_id');
      Cookies.remove('name');
      Cookies.remove('role');
      Cookies.remove('categories');
      Cookies.remove('user_id');
      Cookies.remove('restaurantStatus');
      Cookies.remove('featureAccess');
      Cookies.remove('province');

      window.location.replace('/');
    } else {
      console.log(url, '401 Unauthorized Api Call');
    }
  };

  get = (url, options = {}) => {
    return axios
      .get(url, { ...this.defaultOptions(), ...options })
      .then((res) => {
        if (res.data.message.includes('Not Authorized')) {
          let err = {
            response: {
              status: 401,
            },
          };
          this.LogoutUser(err);
        } else {
          return res;
        }
      })
      .catch((err) => {
        if (err.response.status === 401) {
          this.LogoutUser(err);
          return err;
        } else {
          return err;
        }
      });
  };

  post = (url, data, options = {}) => {
    return axios
      .post(url, data, { ...this.defaultOptions(), ...options })
      .then((res) => {
        if (res.data.message.includes('Not Authorized')) {
          let err = {
            response: {
              status: 401,
            },
          };
          this.LogoutUser(err);
        } else {
          return res;
        }
      })
      .catch((err) => {
        if (err.response.status === 401) {
          this.LogoutUser(err);
          return err;
        } else {
          return err;
        }
      });
  };

  put = (url, data, options = {}) => {
    return axios
      .put(url, data, { ...this.defaultOptions(), ...options })
      .then((res) => {
        if (res.data.message.includes('Not Authorized')) {
          let err = {
            response: {
              status: 401,
            },
          };
          this.LogoutUser(err);
        } else {
          return res;
        }
      })
      .catch((err) => {
        if (err.response.status === 401) {
          this.LogoutUser(err);
          return err;
        } else {
          return err;
        }
      });
  };

  delete = (url, options = {}) => {
    return axios
      .delete(url, { ...this.defaultOptions(), ...options })
      .then((res) => {
        if (res.data.message.includes('Not Authorized')) {
          let err = {
            response: {
              status: 401,
            },
          };
          this.LogoutUser(err);
        } else {
          return res;
        }
      })
      .catch((err) => {
        if (err.response.status === 401) {
          this.LogoutUser(err);
          return err;
        } else {
          return err;
        }
      });
  };
}

export default new Axios();
