import React from 'react';
import {connect} from 'react-redux';
import {Link, withRouter} from 'react-router-dom';
import Drawer from '@material-ui/core/Drawer';
import SidenavContent from './SidenavContent';
import {COLLAPSED_DRAWER, FIXED_DRAWER, HORIZONTAL_NAVIGATION} from 'constants/ActionTypes';
import {toggleCollapsedNav, updateWindowWidth} from 'actions/Setting';
import { API_GET_ADMIN_BANNER } from '../../ApiUrl';
import { Axios } from '../../components';

class SideNav extends React.Component {

  onToggleCollapsedNav = (e) => {
    const val = !this.props.navCollapsed;
    this.props.toggleCollapsedNav(val);
  };

  constructor(props) {
    super(props);
    this.state = {
      drawerOpen: false, bannerImage: "", hyperLink: ""
    };
  }

  componentDidMount() {

    window.addEventListener('resize', () => {
      this.props.updateWindowWidth(window.innerWidth)
    });

    Axios.get(API_GET_ADMIN_BANNER)
      .then(response => {
        if (response.data.status) {
          this.setState({ bannerImage: response.data.data.imageUrl, hyperLink: response.data.data.hyperlink })
        }
      })
      .catch(error => {
        console.log(error)
      })



  }

  toggleDrawer = (drawerOpen, open) => () => {
    this.setState({
      [drawerOpen]: open,
    });
  };

  render() {

    const {navCollapsed, drawerType, width, navigationStyle} = this.props;
    let drawerStyle = drawerType.includes(FIXED_DRAWER) ? 'd-xl-flex' : drawerType.includes(COLLAPSED_DRAWER) ? '' : 'd-flex';
    let type = 'permanent';
    if (drawerType.includes(COLLAPSED_DRAWER) || (drawerType.includes(FIXED_DRAWER) && width < 1200)) {
      type = 'temporary';
    }

    if (navigationStyle === HORIZONTAL_NAVIGATION) {
      drawerStyle = '';
      type = 'temporary';
    }
    return (
      <div className={`app-sidebar d-none ${drawerStyle}`}>
        <Drawer className="app-sidebar-content"
                variant={type}
                open={type.includes('temporary') ? navCollapsed : true}
                onClose={this.onToggleCollapsedNav}
                classes={{
                  paper: 'side-nav',
                }}
        >
          <Link className="app-logo mr-2 d-none d-sm-block" to="/app/dashboard/index">
            <img src={require("assets/images/logo.png")} className="imageClass" alt="Zoomie" title="Zoomie" />
          </Link>
          <SidenavContent />
          {this.state.bannerImage !== "" &&
            <a href={this.state.hyperLink ? this.state.hyperLink : null} target="_blank">
            <img
              src={this.state.bannerImage}
              className='p-2 mb-1'
              width={"220px"}
              height={"350px"}
            />
          </a>
          }
        </Drawer>
      </div>
    );
  }
}

const mapStateToProps = ({settings}) => {
  const {navCollapsed, drawerType, width, navigationStyle} = settings;
  return {navCollapsed, drawerType, width, navigationStyle}
};

export default withRouter(connect(mapStateToProps, {toggleCollapsedNav, updateWindowWidth})(SideNav));
