export function convertUberUndeliverableReasonMessage(undeliverableReason) {
    const reasonMapping = {
        "cancelled_by_merchant_api": "Cancelled By Pharmacy",
        "app_issue": "Driver App Issue",
        "CUSTOMER_UNAVAILABLE": "Customer Unavailable",
        "app issue can't start": "Driver App Issue",
        "NO_SECURE_LOCATION_TO_DROPOFF": "No Secure Location to Drop Off",
        "CANNOT_ACCESS_CUSTOMER_LOCATION": "Cannot Reach Customer"
    };

    return reasonMapping[undeliverableReason] || undeliverableReason;
}
