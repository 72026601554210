/* global google */
import React from "react";
import {
  withGoogleMap,
  GoogleMap,
  withScriptjs,
  Marker,
  DirectionsRenderer,
} from "react-google-maps";

const CarImage = require("../../../../../../assets/images/driver-location.png");

class MapDirectionsRenderer extends React.Component {
  state = {
    directions: null,
    error: null,
  };

  componentDidMount() {
    const { places, travelMode } = this.props;

    const waypoints = places.map((p) => ({
      location: { lat: p.latitude, lng: p.longitude },
      stopover: true,
    }));
    const origin = waypoints.shift().location;
    const destination = waypoints.pop().location;

    const directionsService = new google.maps.DirectionsService();
    directionsService.route(
      {
        origin: origin,
        destination: destination,
        travelMode: travelMode,
        waypoints: waypoints,
      },
      (result, status) => {
        if (status === google.maps.DirectionsStatus.OK) {
          this.setState({
            directions: result,
          });
        } else {
          this.setState({ error: result });
        }
      }
    );
  }

  render() {
    if (this.state.error) {
      return <h1>{this.state.error}</h1>;
    }
    return (
      this.state.directions && (
        <DirectionsRenderer directions={this.state.directions} />
      )
    );
  }
}

const Map = withScriptjs(
  withGoogleMap((props) => {
    const { details } = props;
    const { driverRefId } = details;
    return (
      <GoogleMap defaultZoom={props.defaultZoom}>
        {props.markers.map((marker, index) => {
          const position = { lat: marker.latitude, lng: marker.longitude };
          return (
            <Marker
              key={index}
              position={position}
              onClick={() => this.handleToggleOpen()}
            />
          );
        })}

        {props.details && driverRefId && (
          <Marker
            key={333}
            position={{
              lat: driverRefId.driverLocation.coordinates[1],
              lng: driverRefId.driverLocation.coordinates[0],
            }}
            icon={{
              url: CarImage,
              anchor: new google.maps.Point(17, 46),
              scaledSize: new google.maps.Size(37, 37),
            }}
          />
        )}
        <MapDirectionsRenderer
          places={props.markers}
          travelMode={google.maps.TravelMode.DRIVING}
        />
      </GoogleMap>
    );
  })
);

export default Map;
