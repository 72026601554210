import React from 'react';
import { Redirect, Route, Switch, asyncComponent } from '../../../components';
const Customers = ({ match }) => {
  return (
    <div className="app-wrapper">
      <Switch>
        <Redirect exact from={`${match.url}/`} to={`${match.url}/profile/edit`} />
        <Route path={`${match.url}/profile/edit`} component={asyncComponent(() => import('./routes/profile'))} />
        <Route path={`${match.url}/profile/add-card`} component={asyncComponent(() => import('./routes/customerProfile'))} />
        <Route path={`${match.url}/password-update`} component={asyncComponent(() => import('./routes/password-update'))} />
        <Route component={asyncComponent(() => import('app/routes/extraPages/routes/404'))} />
      </Switch>
    </div>
  )
};

export default Customers;
