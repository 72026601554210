export const PROVINCES = [
  { yukon: 'Yukon' },
  { quebec: 'Quebec' },
  { ontario: 'Ontario' },
  { alberta: 'Alberta' },
  { nunavut: 'Nunavut' },
  { manitoba: 'Manitoba' },
  { novaScotia: 'Nova Scotia' },
  { saskatchewan: 'Saskatchewan' },
  { newBrunswick: 'New Brunswick' },
  { britishColumbia: 'British Columbia' },
  { princeEdwardIsland: 'Prince Edward Island' },
  { northwestTerritories: 'Northwest Territories' },
  { newfoundlandAndLabrador: 'Newfoundland and Labrador' },
];
