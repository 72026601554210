import React, { Component } from "react";
import { NavLink, withRouter } from "react-router-dom";
import Button from "@material-ui/core/Button";
import CustomScrollbars from "util/CustomScrollbars";
import Cookies from "js-cookie";

class SidenavContent extends Component {
  componentDidMount() {
    const { history } = this.props;
    const that = this;
    const pathname = `#${history.location.pathname}`; // get current path

    const subMenuLi = document.querySelectorAll(".sub-menu > li");
    for (let i = 0; i < subMenuLi.length; i++) {
      subMenuLi[i].onclick = function (event) {
        event.stopPropagation();
      };
    }

    const menuLi = document.getElementsByClassName("menu");
    for (let i = 0; i < menuLi.length; i++) {
      menuLi[i].onclick = function (event) {
        for (let j = 0; j < menuLi.length; j++) {
          const parentLi = that.closest(this, "li");
          if (
            menuLi[j] !== this &&
            (parentLi === null || !parentLi.classList.contains("open"))
          ) {
            menuLi[j].classList.remove("open");
          }
        }
        this.classList.toggle("open");
        event.stopPropagation();
      };
    }

    const activeLi = document.querySelector('a[href="' + pathname + '"]'); // select current a element
    try {
      const activeNav = this.closest(activeLi, "ul"); // select closest ul
      if (activeNav.classList.contains("sub-menu")) {
        this.closest(activeNav, "li").classList.add("open");
      } else {
        this.closest(activeLi, "li").classList.add("open");
      }
    } catch (error) { }
  }

  closest(el, selector) {
    try {
      let matchesFn;
      // find vendor prefix
      [
        "matches",
        "webkitMatchesSelector",
        "mozMatchesSelector",
        "msMatchesSelector",
        "oMatchesSelector",
      ].some(function (fn) {
        if (typeof document.body[fn] == "function") {
          matchesFn = fn;
          return true;
        }
        return false;
      });

      let parent;

      // traverse parents
      while (el) {
        parent = el.parentElement;
        if (parent && parent[matchesFn](selector)) {
          return parent;
        }
        el = parent;
      }
    } catch (e) { }

    return null;
  }

  render() {
    const featureAccess = Cookies.get("featureAccess")

    return (
      <CustomScrollbars className=" scrollbar">
        <ul className="nav-menu">
          <li>
            <NavLink to="/app/dashboard/index">
              <i className="zmdi zmdi-view-dashboard zmdi-hc-fw" />
              <span className="nav-text">Dashboard</span>
            </NavLink>
          </li>

          {(featureAccess && featureAccess === "m&d") &&
            <>
              <li className="menu collapse-box">
                <Button href="javascript:void(0)" className="cbutton">
                  <i className="zmdi zmdi-collection-item zmdi-hc-fw" />
                  <span className="nav-text">Products</span>
                </Button>
                <ul className="sub-menu">
                  <li>
                    <NavLink
                      className="prepend-icon cbutton"
                      to="/app/products/list"
                    >
                      <i className="zmdi zmdi-view-list" />
                      <span className="nav-text">All</span>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      className="prepend-icon cbutton"
                      to="/app/products/add"
                    >
                      <i class="zmdi zmdi-plus-circle"></i>

                      <span className="nav-text">Add</span>
                    </NavLink>
                  </li>

                  <li>
                    <NavLink
                      className="prepend-icon cbutton"
                      to="/app/products/import"
                    >
                      <i class="zmdi zmdi-cloud-upload"></i>

                      <span className="nav-text">CSV import</span>
                    </NavLink>
                  </li>
                </ul>
              </li>

              <li>
                <NavLink to="/app/orders/all">
                  <i className="zmdi zmdi-view-list zmdi-hc-fw" />
                  <span className="nav-text">Orders</span>
                </NavLink>
              </li>
            </>
          }

          <li>
            <NavLink to="/app/deliveries/all">
              <i className="zmdi zmdi-car zmdi-hc-fw" />
              <span className="nav-text">Pharmacy Deliveries</span>
            </NavLink>
          </li>

          <li>
            <NavLink to="/app/deliveries/add">
              <i className="zmdi zmdi-car zmdi-hc-fw" />
              <span className="nav-text">Quick Add</span>
            </NavLink>
          </li>

          <li className="menu collapse-box">
            <Button href="javascript:void(0)" className="cbutton">
              <i className="zmdi zmdi-money-box zmdi-hc-fw" />
              <span className="nav-text">Expenses</span>
            </Button>
            <ul className="sub-menu">
              <li>
                <NavLink className="prepend-icon cbutton" to="/app/expenses/monthly">
                  <i className="zmdi zmdi-money-box zmdi-hc-fw" />
                  <span className="nav-text">Monthly Expenses</span>
                </NavLink>
              </li>
              <li>
                <NavLink className="prepend-icon cbutton" to="/app/expenses/all">
                  <i className="zmdi zmdi-money-box zmdi-hc-fw" />
                  <span className="nav-text">All Expenses</span>
                </NavLink>
              </li>
            </ul>
          </li>

          <li>
            <NavLink to="/app/patients/all">
              <i className="zmdi zmdi-account zmdi-hc-fw" />
              <span className="nav-text">Patients</span>
            </NavLink>
          </li>
          <li>
            <NavLink to="/app/recurring/all">
              <i className="zmdi zmdi-replay zmdi-hc-fw" />
              <span className="nav-text">Recurring orders</span>
            </NavLink>
          </li>
          <li className="menu collapse-box">
            <Button href="javascript:void(0)" className="cbutton">
              <i class="zmdi zmdi-balance-wallet"></i>
              <span className="nav-text">Payments</span>
            </Button>
            <ul className="sub-menu">
              <li>
                <NavLink
                  className="prepend-icon cbutton"
                  to="/app/payments/add-payment"
                >
                  <i class="zmdi zmdi-money-box"></i>
                  <span className="nav-text">make payment</span>
                </NavLink>
                <NavLink
                  className="prepend-icon cbutton"
                  to="/app/settings/profile/add-card"
                >
                  <i className="zmdi zmdi-money-box" />
                  <span className="nav-text">Add Credit Card</span>
                </NavLink>
              </li>

              <li>
                <NavLink
                  className="prepend-icon cbutton"
                  to="/app/payments/all"
                >
                  <i class="zmdi zmdi-replay"></i>
                  <span className="nav-text">payment history</span>
                </NavLink>
              </li>
            </ul>
          </li>

          <li className="menu collapse-box">
            <Button href="javascript:void(0)" className="cbutton">
              <i className="zmdi zmdi-settings zmdi-hc-fw" />
              <span className="nav-text">Settings</span>
            </Button>
            <ul className="sub-menu">
              <li>
                <NavLink
                  className="prepend-icon cbutton"
                  to="/app/settings/profile/edit"
                >
                  <i className="zmdi zmdi-account-circle" />
                  <span className="nav-text">Profile</span>
                </NavLink>
              </li>

              <li>
                <NavLink
                  className="prepend-icon cbutton"
                  to="/app/notifications/list"
                >
                  <i className="zmdi zmdi-account-circle" />
                  <span className="nav-text">All Notification</span>
                </NavLink>
              </li>
              <li>
                <NavLink
                  className="prepend-icon cbutton"
                  to="/app/settings/password-update"
                >
                  <i class="zmdi zmdi-album"></i>

                  <span className="nav-text">Update Password</span>
                </NavLink>
              </li>
            </ul>
          </li>
          <li>
            <a href="https://meds-on-wheels.com/mdsadmin/login" target="_blank">
              <i className="zmdi zmdi-arrow-right-top zmdi-hc-fw" />
              <span className="nav-text">Go to Old Panel</span>
            </a>
          </li>
        </ul>
      </CustomScrollbars>
    );
  }
}

export default withRouter(SidenavContent);
