import React, { Fragment } from 'react';

import Cookies from 'js-cookie';

import { withRouter, connect, TEXTFIELD, toast, Axios, PASSWORDFIELD, Row, Col, SUBMIT, RESET, CircularProgress } from '../components';
import { API_LOGIN } from '../ApiUrl';
import './includes/css/style.css';

import Notifications from '../components/Notifications/Notifications';

import { hideMessage, showAuthLoader, userSignInSuccess, hideAuthLoader } from 'actions/Auth';

class SignIn extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: '',
      disabled: false,
    };
  }

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };
  loginUser = (e) => {
    e.preventDefault();
    this.setState({
      loading: true,
      disabled: true,
    });
    this.props.showAuthLoader();
    const { email, password } = this.state;
    const formData = {
      email: email.trim(),
      password: password.trim(),
      firebaseToken: Cookies.get('firebaseToken') || '',
    };

    Axios.post(API_LOGIN, formData)
      .then((response) => {
        let data = response.data;

        if (data.status == 'failure') {
          toast.error(data.message, {
            toastId: 'customId',
          });
        } else {
          data = data.data;

          Cookies.set('token', data.token, { sameSite: 'strict', secure: false });
          Cookies.set('_id', data._id, { sameSite: 'strict', secure: false });
          Cookies.set('name', data.name, { sameSite: 'strict', secure: false });
          Cookies.set('role', data.role, { sameSite: 'strict', secure: false });
          Cookies.set('categories', data.categories[0], { sameSite: 'strict', secure: false });
          Cookies.set('user_id', data.token, { sameSite: 'strict', secure: false });
          Cookies.set('restaurantStatus', data.restaurantStatus, { sameSite: 'strict', secure: false });
          Cookies.set('featureAccess', data.featureAccess, { sameSite: 'strict', secure: false });
          Cookies.set('province', data.province, { sameSite: 'strict', secure: false });

          this.props.userSignInSuccess(data.token);

          toast.success('Sign in successful.');

          setTimeout(() => {
            this.props.history.push('/app/dashboard/index');
          }, 1000);
        }
        this.setState({
          loading: false,
          disabled: false,
        });
        this.props.hideAuthLoader();
      })
      .catch((err) => {
        let error = err.response;
        if (error !== undefined) {
          toast.error(error.data.message);
        } else {
          toast.error('server error, Please try after some time.', {
            toastId: 'customId',
          });
        }
        this.setState({
          loading: false,
          disabled: false,
        });
        this.props.hideAuthLoader();
      });
  };

  reset = () => {
    this.setState({
      email: '',
      password: '',
    });
  };

  render() {
    const { email, password, loading, disabled } = this.state;

    return (
      <div className="app-login-content">
        {/* <Notifications /> */}
        {loading && (
          <div className="loader-view">
            <CircularProgress />
          </div>
        )}
        <Row>
          <form onSubmit={(e) => this.loginUser(e)}>
            <Col xm="12">
              <TEXTFIELD label="Enter Email" fullWidth name="email" onChange={this.handleChange} value={email} errors={{}} />
            </Col>
            <Col xm="12">
              <PASSWORDFIELD label="Password" fullWidth name="password" onChange={this.handleChange} value={password} errors={{}} />
            </Col>
            <Col>
              <p style={{ color: '#0A7FC1', cursor: 'pointer' }} onClick={() => this.props.changeView('forgot')}>
                Forgot password?
              </p>
            </Col>
            <Col>
              <Row>
                <Col md="6" xs="12">
                  <SUBMIT style={{ backgroundColor: disabled ? 'grey' : 'green', color: 'white' }} title="Sign In" disabled={disabled} />
                </Col>
                <Col md="6" xs="12">
                  <RESET onClick={this.reset} />
                </Col>
              </Row>
            </Col>
          </form>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = ({ auth }) => {
  const { loader, alertMessage, showMessage, authUser } = auth;
  return { loader, alertMessage, showMessage, authUser };
};

export default connect(mapStateToProps, {
  hideMessage,
  showAuthLoader,
  userSignInSuccess,
  hideAuthLoader,
})(withRouter(SignIn));
