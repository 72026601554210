import { Link, NavLink, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { toast, ToastContainer } from 'react-toastify';
import { Redirect, Route, Switch } from 'react-router-dom';
import asyncComponent from '../util/asyncComponent';
import IntlMessages from 'util/IntlMessages';
import Avatar from '@material-ui/core/Avatar';
import CircularProgress from '@material-ui/core/CircularProgress';
import Axios from '../actions/Axios';
import Grid from '@material-ui/core/Grid';
import CardMedia from '@material-ui/core/CardMedia';
import Rating from '@material-ui/lab/Rating';
import {
  TEXTFIELD,
  SUBMIT,
  RESET,
  PASSWORDFIELD,
  UPDATEPASSWORD,
  SELECT,
  SELECT2,
  BUTTON,
  FILEUPLOAD,
  DATEPICKER,
  MONTHDATEPICKER,
  DATETIMEPICKER,
  CHECKBOX,
  GoogleAutocomplete,
  SEARCHFILTER,
} from './Common/InputFields';
import { COUNTRIES, PHONECODES } from './Common/countries';
import {
  DataTable,
  DataTableHead,
  DataTableBody,
  ActionLink,
  DataTablePagination,
  Action,
  ActionPopup,
  TableBody,
  TableRow,
  TableCell,
  moment,
} from './Common/DataTable';
import InfoCard from 'components/InfoCard';
import ContainerHeader from 'components/ContainerHeader/index';
import Widget from 'components/Widget/index';
import CardBox from './CardBox/index';
import Paper from '@material-ui/core/Paper';
import CKEditor from 'react-ckeditor-component';
import { checkValidations, checkError, Format_Number } from '../actions/helper';
import { DialogBox, NavLinkButton, Accodian } from '../components/Common';
import NotFound from '../components/Common/extra/notFound';
import Loading from '../components/Common/extra/loading';
import ImagePreview from './Common/extra/ImagePreview';
import {
  CardBody,
  CardText,
  Container,
  Row,
  Col,
  CardHeader,
  CardImg,
  Card,
  Media,
  Badge,
  Alert,
  UncontrolledTooltip,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'reactstrap';
import Chip from '@material-ui/core/Chip';
import CSVReader from 'react-csv-reader';

import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

import Status from './Status';
const COLORS = '#84c225';
const Logo = require('assets/images/logo.png');
const ApiUrl = require('../ApiUrl');
const noImage = ['', null, undefined, 'none'];

export {
  Input,
  InputLabel,
  MenuItem,
  FormControl,
  Select,
  Logo,
  Chip,
  Rating,
  Badge,
  Loading,
  ImagePreview,
  NotFound,
  ApiUrl,
  noImage,
  COLORS,
  Status,
  CardBody,
  CardText,
  Alert,
  Avatar,
  CardImg,
  CardHeader,
  Card,
  CardMedia,
  Media,
  COUNTRIES,
  PHONECODES,
  Link,
  NavLink,
  withRouter,
  Redirect,
  Route,
  Switch,
  asyncComponent,
  connect,
  checkValidations,
  checkError,
  TextField,
  Button,
  UPDATEPASSWORD,
  DialogBox,
  NavLinkButton,
  Accodian,
  IntlMessages,
  CircularProgress,
  Axios,
  ToastContainer,
  toast,
  Widget,
  Paper,
  CardBox,
  CKEditor,
  TEXTFIELD,
  GoogleAutocomplete,
  SUBMIT,
  RESET,
  PASSWORDFIELD,
  SELECT,
  SELECT2,
  CSVReader,
  Grid,
  Container,
  Row,
  Col,
  BUTTON,
  FILEUPLOAD,
  SEARCHFILTER,
  DATEPICKER,
  MONTHDATEPICKER,
  DATETIMEPICKER,
  CHECKBOX,
  Format_Number,
  DataTable,
  DataTableHead,
  ActionLink,
  DataTableBody,
  DataTablePagination,
  Action,
  ActionPopup,
  TableBody,
  TableRow,
  TableCell,
  moment,
  InfoCard,
  ContainerHeader,
  UncontrolledTooltip,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
};
