export const provinceTaxes = {
  ontario: 13.00,
  quebec: 14.975,
  alberta: 5.00,
  manitoba: 0.53,
  britishColumbia: 5.00,
  saskatchewan: 5.00,
  newBrunswick: 5.00,
  novaScotia: 15.00,

  yukon: null,
  nunavut: null,
  princeEdwardIsland: null,
  northwestTerritories: null,
  newfoundlandAndLabrador: null,
};


export function roundToTwo(num) {
  return +(Math.round(num + "e+2")  + "e-2");
}