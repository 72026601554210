import React, { useState } from "react";
import Button from "@material-ui/core/Button";
// import ExportCsv from "../../../ExportCsv"
import {
  TEXTFIELD,
  SELECT,
  BUTTON,
  NavLinkButton,
  CHECKBOX,
  DATEPICKER,
} from "../../../../../components/";

const Filters = (props) => {
  const {
    fieldName,
    startDate,
    endDate,
    fieldValue,
    filterData,
    datefilter,
    removefilter,
    handleChange,
    handleStartDate,
    handleEndDate,
    status,
    fieldsNames,
    exportButton,
  } = props;
  const { file, url, params, columns } = exportButton;
  let disabled =
    (!fieldName && !fieldValue) || !endDate || !startDate ? true : "";
  return (
    <div className="list-filters">
      <div className="row">
        <div className="col-sm-1">
          <div className="flex-auto Filtertripbutton">
            <Button className="jr-btn CBmargin CBmargin" onClick={removefilter}>
              <i className="zmdi zmdi-refresh-sync" />
            </Button>
          </div>
        </div>
        <div
          className={"SelectTrip SelectCustomer col-sm-2"}
          style={{ margin: "3px 0px" }}
        >
          {fieldsNames && (
            <SELECT
              label="Filter Name"
              name="fieldName"
              all={true}
              onChange={(e) => handleChange(e)}
              value={fieldName}
              data={fieldsNames}
            />
          )}
        </div>

        {["customerName", "customOrderId"].includes(fieldName) && (
          <div className="col-sm-2">
            <TEXTFIELD
              label="Search"
              type="search"
              name="fieldValue"
              value={fieldValue}
              onChange={(e) => handleChange(e)}
              className="SearchtextField"
            />
          </div>
        )}
        {fieldName === "paymentMethod" && (
          <div className={"SelectTrip SelectCustomer col-sm-2"}>
            <SELECT
              label="Status"
              name="fieldValue"
              onChange={(e) => handleChange(e)}
              value={fieldValue}
              data={[{ cash: "Cash" }, { stripe: "Card" }]}
            />
          </div>
        )}
        {fieldName === "orderStatus" && (
          <div className={"SelectTrip SelectCustomer col-sm-2"}>
            <SELECT
              label="Status"
              name="fieldValue"
              onChange={(e) => handleChange(e)}
              value={fieldValue}
              data={status}
            />
          </div>
        )}
        <div className="col-sm-2">
          <BUTTON onClick={filterData} disabled={disabled} />
        </div>
        {/* <div className="col-sm-2">
          <NavLinkButton
            style={{ marginTop: 15 }}
            title={"Create Order"}
            link={"/app/orders/add"}
            iclass="zmdi-plus-circle"
          ></NavLinkButton>
        </div> */}
      </div>
    </div>
  );
};

export default Filters;
