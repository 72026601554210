import React from 'react';

import Cookies from 'js-cookie';

import {
  toast,
  withRouter,
  Row,
  Col,
  Axios,
  TEXTFIELD,
  PASSWORDFIELD,
  GoogleAutocomplete,
  SUBMIT,
  RESET,
  ApiUrl,
  checkValidations,
  checkError,
  CircularProgress,
  CHECKBOX,
  SELECT,
  connect,
  PHONECODES,
} from '../components';

import { PASS_REGEX } from '../ApiUrl';
import { PROVINCES } from '../constants/provinces';

import { hideMessage, showAuthLoader, userSignInSuccess, hideAuthLoader } from 'actions/Auth';

const { EMAIL_REGEX, PASS_VALIDATION_MSG, US_NUMERIC_WITH_OR_WITHOUT_PLUS_AT_START, API_ADD_STORES, API_EDIT_STORES, API_GET_CATEGORY_URL } = ApiUrl;

class SignIn extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      checked: false,
      editId: this.props.editId,
      process: this.props.role,
      imagePreviewUrl: '',
      details: this.props.details,
      loading: false,
      categoriesList: [],
      cuisinesList: [],
      name: '',
      vendorName: '',
      email: '',
      country: '',
      mobileNumber: '',
      countryCode: '+1',
      // city: '',
      province: '',
      address: '',
      lng: '',
      lat: '',
      cuisines: [],
      selectedOption: [],
      categories: [],
      images: {},
      businessproof: {},
      password: '',
      password2: '',
      imagePreviewUrl: '',
      accreditationNumber: '',
      groupCode: '',
      discription: '',
      errors: {
        name: '',
        email: '',
        vendorName: '',
        country: '',
        city: '',
        address: '',
        lng: '',
        lat: '',
        cuisines: '',
        accreditationNumber: '',
        images: this.props.role !== 'edit' ? '' : '',
        businessproof: this.props.role !== 'edit' ? '' : '',
      },
      disabled: true,
    };
  }

  // componentDidMount() {
  //   const { editId, process } = this.state;
  //   this.setState({ loading: true });
  //   Axios.post(API_GET_CATEGORY_URL, {
  //     limit: 1000,
  //     order: 1,
  //     orderBy: 'featuredPosition',
  //   })
  //     .then((res) => {
  //       let data = res.data.data;
  //       if (data.length > 0) {
  //         data = data.map((key) => {
  //           return {
  //             [key._id]: key.catName,
  //           };
  //         });
  //         this.setState({ categoriesList: data });
  //       } else {
  //         toast.error('Please first add categories.');
  //       }
  //     })
  //     .catch((err) => {
  //       //console.log("Server error");
  //     });

  //   this.setState({ loading: false });
  // }

  handleCheck = (event) => {
    this.setState({ checked: event.target.checked });
  };

  handleChange = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    let finalVal = value;
    let errors = this.state.errors;

    switch (name) {
      case 'name':
        errors.name = value.length < 1 ? 'Required' : '';
        break;
      case 'vendorName':
        errors.vendorName = value.length < 1 ? 'Required' : '';
        break;
      case 'email': {
        let err = '';
        if (value.length < 1) {
          err = 'Required';
        } else {
          if (!EMAIL_REGEX.test(value)) {
            err = 'Invalid email';
          }
        }
        errors.email = err;
        break;
      }
      case 'password': {
        let err = '';
        if (value.length < 1) {
          err = 'Required';
        } else {
          if (!PASS_REGEX.test(value)) {
            err = 'Invalid Password';
          }
        }
        errors.password = err;
        break;
      }
      case 'password2': {
        let err = '';
        if (value.length < 1) {
          err = 'Required';
        } else {
          if (this.state.password !== value) {
            err = 'Confirm Password should be match to Password';
          }
        }
        errors.password2 = err;
        break;
      }
      case 'mobileNumber': {
        let err = '';
        if (value.length < 1) {
          err = 'Required';
        } else {
          if (!US_NUMERIC_WITH_OR_WITHOUT_PLUS_AT_START.test(value.replace('+1', ''))) {
            err = "Only numeric allowed and phone number should be valid";
            // finalVal = '';
          }
        }
        errors.mobileNumber = err;

        break;
      }

      // case 'city':
      //   errors.city = value.length < 1 ? 'Required' : '';
      //   break;

      case 'address':
        errors.address = value.length < 1 ? 'Required' : '';
        break;

      case 'categories':
        errors.categories = value.length < 1 ? 'Required' : '';
        break;

      default:
        break;
    }

    this.setState({ errors, [name]: finalVal }, () => {
      let err = checkError(errors);
      this.setState({ disabled: err.disabled });
    });
  };

  redirectPage = () => {
    this.props.history.push('/');
  };

  handleAddress = (address) => {
    let errors = this.state.errors;
    let add = address;
    errors.address = address.address < 1 ? 'Required' : '';
    add.errors = errors;
    this.setState(add);
  };

  handleSubmit = (event) => {
    event.preventDefault();

    if (!this.state.checked) return toast.error('Please accept the Terms of Service.');

    this.setState({ loading: true, disabled: true });

    const {
      name,
      email,
      mobileNumber,
      countryCode,
      // city,
      province,
      address,
      lng,
      lat,
      images,
      password,
      password2,
      editId,
      process,
      accreditationNumber,
      groupCode,

    } = this.state;

    let data = {
      name: name,
      email: email,
      mobileNumber: mobileNumber,
      address: address,
      // city: city,
      province: province,
      lat: lat,
      lng: lng,
      password: password,
      password2: password2,
      countryCode: countryCode,
      accreditationNumber: accreditationNumber,
    };

    console.log({ data });

    let valid = checkValidations(data);

    if (valid.error) {
      toast.error(valid.error);
      this.setState({ loading: false, disabled: false });
      return false;
    }

    // groupCode is not required for signup so bypass checkValidations
    data = { ...valid.data, groupCode: groupCode };

    if (images.name !== undefined) {
      data.profileImage = images;
    }

    let url = API_ADD_STORES;

    if (process == 'edit') {
      data.storeid = editId;
      url = API_EDIT_STORES;
    }

    let formData = new FormData();

    for (var key in data) {
      formData.append(key, data[key]);
    }

    Axios.post(url, formData)
      .then((res) => {
        let data = res.data;
        const { status, message } = data;
        if (status === 'failure') {
          toast.error(message);
          this.setState({ loading: false, disabled: false });
          return false;
        } else {
          data = data.data;

          Cookies.set('token', data.token, { sameSite: 'strict', secure: false });
          Cookies.set('_id', data._id, { sameSite: 'strict', secure: false });
          Cookies.set('name', data.name, { sameSite: 'strict', secure: false });
          Cookies.set('role', data.role, { sameSite: 'strict', secure: false });
          Cookies.set('categories', data.categories[0], { sameSite: 'strict', secure: false });
          Cookies.set('user_id', data.token, { sameSite: 'strict', secure: false });
          Cookies.set('restaurantStatus', data.restaurantStatus, { sameSite: 'strict', secure: false });
          Cookies.set('featureAccess', data.featureAccess, { sameSite: 'strict', secure: false });

          this.props.userSignInSuccess(data.token);

          toast.success('Sign up successful');

          setTimeout(() => {
            this.redirectPage();
          }, 1000);
        }
      })
      .catch((error) => {
        console.log({ error });
        toast.error("Something went wrong!"); 
        this.setState({ loading: false, disabled: false });
      });
  };

  reset = () => {
    this.setState({
      imagePreviewUrl: '',
      name: '',
      vendorName: '',
      email: '',
      country: '',
      mobileNumber: '',
      countryCode: '+1',
      // city: '',
      province: '',
      address: '',
      lng: '',
      lat: '',
      discription: '',
      cuisines: [],
      categories: [],
      images: {},
      password: '',
      password2: '',
      accreditationNumber: '',
      groupCode: '',
      businessproof: {},
      errors: {
        name: '',
        vendorName: '',
        email: '',
        country: '',
        // city: '',
        province: '',
        address: '',
        lng: '',
        lat: '',
        cuisines: '',
        categories: '',
        images: this.props.role !== 'edit' ? 'Required' : '',
      },
      disabled: true,
    });
  };

  render() {
    const {
      name,
      email,
      mobileNumber,
      countryCode,
      city,
      province,
      address,
      lng,
      lat,
      password,
      password2,
      errors,
      disabled,
      loading,
      accreditationNumber,
      groupCode,
    } = this.state;

    return (
      <div className="app-signup-content">
        {loading && (
          <div className="loader-view">
            <CircularProgress />
          </div>
        )}

        <form onSubmit={this.handleSubmit} noValidate>
          <Row>
            <Col md="12" xs="12">
              <Row>
                <Col md="6" xs="12">
                  <TEXTFIELD label="Pharmacy Name" value={name} name={'name'} onChange={this.handleChange} errors={errors} />
                </Col>

                <Col md="6" xs="12">
                  <TEXTFIELD label="Email" value={email} name={'email'} onChange={this.handleChange} errors={errors} />
                </Col>

                {/* <Col md="6" xs="12">
                  <SELECT
                    label="Country Code"
                    value={countryCode}
                    all={true}
                    name="countryCode"
                    data={PHONECODES}
                    errors={errors}
                    onChange={this.handleChange}
                  />
                </Col> */}

                <Col md="6" xs="12">
                  <TEXTFIELD label="Pharmacy Number" value={mobileNumber} name={'mobileNumber'} onChange={this.handleChange} errors={errors} />
                </Col>

                <Col md="6" xs="12">
                  <TEXTFIELD
                    label="Accreditation Number"
                    value={accreditationNumber}
                    name={'accreditationNumber'}
                    onChange={this.handleChange}
                    errors={errors}
                  />
                </Col>

             

                <Col md="6">
                  <GoogleAutocomplete
                    label="Address"
                    address={address}
                    lat={lat}
                    lng={lng}
                    errors={errors}
                    name="address"
                    onChange={this.handleAddress}
                  />
                </Col>

                {/* <Col md="6" xs="12">
                  <TEXTFIELD label="City" value={city} name={'city'} onChange={this.handleChange} errors={errors} />
                </Col> */}

                <Col md="6" xs="12">
                  <SELECT
                    label="Province"
                    value={province}
                    all={true}
                    name="province"
                    data={PROVINCES}
                    errors={errors}
                    onChange={this.handleChange}
                  />
                </Col>

                <Col md="6" xs="12">
                  <TEXTFIELD
                    label="Group Code (optional)"
                    value={groupCode}
                    name={'groupCode'}
                    onChange={this.handleChange}
                    errors={errors}
                  />
                </Col>

                <Col md="6" xs="12">
                  <PASSWORDFIELD
                    label="Password"
                    value={password}
                    name={'password'}
                    onChange={this.handleChange}
                    note={PASS_VALIDATION_MSG}
                    errors={errors}
                  />
                </Col>

                <Col md="6" xs="12">
                  <PASSWORDFIELD
                    label="Confirm Password"
                    value={password2}
                    name={'password2'}
                    onChange={this.handleChange}
                    note={PASS_VALIDATION_MSG}
                    errors={errors}
                  />
                </Col>
              </Row>
              <Row>
                <Col md="6" xs="12">
                  <CHECKBOX
                    name="tos"
                    labelPlacement="end"
                    // label="I accept the Terms of Service"
                    value={this.state.checked}
                    onChange={this.handleCheck}
                    label={
                      <span>
                        I accept the{' '}
                        <a href="https://meds-on-wheels.com/terms-of-service/" target="_blank" rel="noopener noreferrer">
                          Terms of Service
                        </a>
                      </span>
                    }
                  />
                </Col>
              </Row>
              <Row>
                <Col md="6" xs="12">
                  <SUBMIT style={{ backgroundColor: disabled ? 'grey' : 'green', color: 'white' }} title="Sign Up" disabled={disabled} />
                </Col>
                <Col md="6" xs="12">
                  <RESET onClick={this.reset} />
                </Col>
              </Row>
            </Col>
          </Row>
        </form>
      </div>
    );
  }
}

const mapStateToProps = ({ auth }) => {
  const { loader, alertMessage, showMessage, authUser } = auth;
  return { loader, alertMessage, showMessage, authUser };
};

export default connect(mapStateToProps, {
  hideMessage,
  showAuthLoader,
  userSignInSuccess,
  hideAuthLoader,
})(withRouter(SignIn));
