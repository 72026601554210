import React, { Component } from "react";
import {
  asyncComponent,
  Button,
  withRouter,
  Link,
} from "../../../../../components";
import ListAll from "./ListAll";
var tabs = {};
export default class NavTab extends Component {
  render() {
    return (
      <div>
        <div className="tab-container">
          <div className="tabs">
            <Link to="/app/orders/all" className="tab active">
              All Orders
            </Link>
            <Link to="/app/orders/draft" className="tab">
              Draft Orders
            </Link>
            <Link to="/app/orders/assignedOrders" className="tab ">
              Assigned Orders
            </Link>
            <Link to="/app/orders/pickedOrders" className="tab ">
              Picked Orders
            </Link>
            <Link to="/app/orders/deliveredOrders" className="tab ">
              Delivered Orders
            </Link>
            {/* <Link to="/app/orders/new" className="tab">
              Profile
            </Link>
            <Link to="/app/orders/completed" className="tab">
              Messages
            </Link> */}
          </div>
        </div>
      </div>
    );
  }
}
