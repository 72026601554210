import React, { Component, Fragment } from "react";
import {
  withRouter,
  CircularProgress,
  Button,
  toast,
  Avatar,
  Axios,
  TableBody,
  TableRow,
  TableCell,
  DataTable,
  DataTableBody,
  Action,
  DataTableHead,
  DataTablePagination,
  DialogBox,
  NavLink,
  Loading,
  moment,
  Status,
  ApiUrl,
} from "../../../../../components";
import ListItemIcon from "@material-ui/core/ListItemIcon";

import RoomIcon from "@material-ui/icons/Room";
import Filters from "./Filters";
import Details from "./Details";
const { API_GET_ORDERS } = ApiUrl;

const RouteMap = (value) => {
  return (
    <Fragment>
      {value.serviceType === "delivery" && (
        <NavLink to={`/app/orders/tracking/${value._id}`}>
          <Button className="bg-amber text-white CBmargin">
            <i className={`${"zmdi zmdi-navigation"}`} />
          </Button>
        </NavLink>
      )}
    </Fragment>
  );
};

/* Display the Payment Method (ex: Cash or Stripe) */
const getPayment = (value) => {
  return (
    <span className={`label 336 ${value.paymentMethod || "cash"}`}>

      {/* Capitalize the first letter of Cash or Stripe */}
      {(value.paymentMethod || "cash").charAt(0).toUpperCase() + (value.paymentMethod || "cash").slice(1) || "Cash"}
    </span>
  );
};

const columnData = [
  { id: "_id", align: false, disablePadding: true, label: "S.no", order: 1 },
  {
    id: "orderCreatedAt",
    label: "Order Date",
    order: 1,
    type: "date",
  },

  {
    id: "customOrderId",
    align: false,
    disablePadding: true,
    label: "Order ID",
    order: 1,
    type: "tag",
  },
  {
    id: "customerName",
    align: true,
    disablePadding: false,
    label: "Customer",
    order: 1,
  },
  // {
  //   id: "driverName",
  //   align: true,
  //   disablePadding: false,
  //   label: "Driver",
  //   order: 1
  // },
  // {
  //   id: "distance",
  //   align: true,
  //   disablePadding: false,
  //   label: "Distance(Miles)",
  //   order: 1
  // },
  {
    id: "orderTotal",
    align: true,
    disablePadding: false,
    label: "Amount",
    order: 1,
    type: "currency",
  },
  {
    id: "paymentMethod",
    align: true,
    disablePadding: false,
    label: "Payment",
    order: 1,
    type: "action",
    action: getPayment,
  },
  {
    id: "paymentStatus",
    align: true,
    disablePadding: false,
    label: "Payment Status",
    order: 1,
    type: "tag",
  },

  {
    id: "orderType",
    align: true,
    disablePadding: false,
    label: "Type",
    order: 1,
    type: "tag",
  },
  {
    id: "serviceType",
    align: true,
    disablePadding: false,
    label: "Service Type",
    order: 1,
    type: "tag",
  },
  {
    id: "orderStatus",
    align: true,
    disablePadding: false,
    label: "Status",
    order: 1,
    type: "tag",
  },
  {
    id: "map",
    align: true,
    disablePadding: false,
    label: "Map",
    order: 1,
    type: "action",
    action: RouteMap,
  },
  { id: "action", align: true, disablePadding: false, label: "Action" },
];

class ListAll extends Component {
  constructor(props) {
    super(props);
    //console.log(this.props, 5665656565);
    this.state = {
      columns: columnData,
      status: this.props.status,
      timer: this.props.timer,
      statusforFilter: [],
      fieldsNames: [],
      dumpId: "",
      data: [],
      totalCount: 0,
      rowsPerPage: 10,
      page: 0,
      order: -1,
      fieldName: "customerName",
      fieldValue: "",
      datefilter: false,
      startDate: new Date(Date.now()),
      endDate: new Date(Date.now()),
      isAssign: "",
      orderBy: "orderCreatedAt",
      open: false,
      selectedCompany: "",
      alertClass: "success",
      alertVisible: false,
      alertMessage: "",
    };
  }

  componentDidMount = () => {
    let statusforFilter = [];
    let fieldsNames = [
      { customerName: "Customer name" },
      // { driverName: "Driver name" },
      { orderStatus: "Order Status" },
      { customOrderId: "Order Id" },
      { paymentMethod: "Payment Method" },
    ];

    statusforFilter = this.state.status.map((key) => {
      return { [key]: Status[key] };
    });
    // console.log;
    this.setState({
      fieldsNames: fieldsNames,
      statusforFilter: statusforFilter,
    });
    this.getTableRecords(this.state.order, this.state.orderBy);
    if (this.props.new) {
      this.runInterval = setInterval(() => {
        this.getTableRecords(this.state.order, this.state.orderBy);
      }, 30000);
    }
  };
  componentWillUnmount() {
    clearInterval(this.runInterval);
  }
  handleChangePage = (event, page) => {
    this.setState({ page: page }, () =>
      this.getTableRecords(this.state.order, this.state.orderBy)
    );
  };

  handleRequestSort = (data) => {
    if (data.key !== "action") {
      let NewColoumns = this.state.columns.map((key, index) => {
        if (key.id !== data.key) {
          return { ...key, order: 1 };
        } else {
          return { ...key, order: data.order };
        }
      });

      this.setState(
        {
          loading: true,
          columns: NewColoumns,
          orderBy: data.key,
          order: data.order,
        },
        () => this.getTableRecords(data.order, data.key)
      );
    }
  };

  getTableRecords = async (order, orderBy) => {
    const {
      fieldName,
      fieldValue,
      page,
      rowsPerPage,
      status,
      endDate,
      startDate,
      datefilter,
    } = this.state;
    this.setState({ loading: true });
    let body = {
      orderBy: orderBy,
      order: order,
      page: parseInt(page) + 1,
      limit: rowsPerPage,
      status,
    };
    if (datefilter) {
      body.startDate = moment(startDate).format("YYYY-MM-DD");
      body.endDate = moment(endDate).format("YYYY-MM-DD");
    }
    if (fieldName || fieldValue) {
      let name = fieldName;
      body.fieldName = name;
      body.fieldValue = fieldValue;
    }

    Axios.post(API_GET_ORDERS, body)
      .then((response) => {
        response = response.data;
        this.setState({
          totalCount: response.totalcount,
          data: response.data,
          loading: false,
        });
      })
      .catch((err) => {
        console.log("No data found!");
      });
  };

  handleChange = (event) => {
    let value = event.target.value;
    if (event.target.name === "datefilter") {
      value = !this.state.datefilter;
    }
    this.setState({ [event.target.name]: value });
  };

  handleStartDate = (date) => {
    const { startDate, endDate } = this.state;

    if (new Date(endDate) < new Date(date)) {
      toast.success(
        "Please select `Start date` greater than or equal to `End date`."
      );
    } else {
      this.setState({ startDate: date });
    }
  };
  handleEndDate = (date) => {
    const { startDate, endDate } = this.state;

    if (new Date(startDate) > new Date(date)) {
      toast.success(
        "Please select `Start date` greater than or equal to `End date`."
      );
    } else {
      this.setState({ endDate: date });
    }
  };

  handleClickOpen = (status, id) => {
    //console.log(id, status, 6556);
    this.setState({
      open: true,
      dumpId: id,
    });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  filterData = () => {
    this.getTableRecords(1, "orderCreatedAt");
  };

  removefilterbutton = () => {
    this.setState(
      {
        data: [],
        open: false,
        dumpId: "",
        fieldName: "customerName",
        fieldValue: "",
        datefilter: false,
      },
      () => {
        this.getTableRecords(1, "orderCreatedAt");
      }
    );
  };
  onDismiss = () => {
    this.setState({
      alertVisible: false,
      alertMessage: "",
      alertClass: "success",
      successMessage: "",
    });
  };

  detailsPopup = (state, id = "") => {
    this.setState({ open: state, dumpId: id });
  };

  handleDelete = (e, id) => {
    //  console.log(e, id, 56656565);
  };

  render() {
    const {
      data,
      fieldName,
      fieldValue,
      status,
      order,
      orderBy,
      startDate,
      endDate,
      rowsPerPage,
      page,
      totalCount,
      alertClass,
      alertVisible,
      alertMessage,
      loading,
      datefilter,
      isAssign,
      zipCode,
      dumpId,
      open,
      statusforFilter,
      fieldsNames,
    } = this.state;
    //console.log(this.props, "orjkdf");
    return (
      <Fragment>
        <Filters
          fieldName={fieldName}
          fieldsNames={fieldsNames}
          status={statusforFilter}
          handleChange={this.handleChange}
          datefilter={datefilter}
          startDate={startDate}
          endDate={endDate}
          handleStartDate={this.handleStartDate}
          handleEndDate={this.handleEndDate}
          fieldValue={fieldValue}
          filterData={this.filterData}
          removefilter={this.removefilterbutton}
          exportButton={{
            file: "Trips.csv",
            columns: { columnData },
            url: API_GET_ORDERS,
            params: {
              orderBy: "createdBy",
              order: "-1",
              fieldName: fieldName,
              fieldValue: fieldValue,
              page: 0,
              limit: totalCount,
              datefilter: datefilter,
              startDate: startDate,
              endDate: endDate,
              status: status,
              datefield: "tripCreatedAt",
            },
          }}
        />
        {dumpId !== "" && (
          <Details id={dumpId} detailsPopup={this.detailsPopup} open={open} />
        )}
        <div className="flex-auto">
          <div className="table-responsive-material">
            <DataTable className="">
              <DataTableHead
                columns={this.state.columns}
                orderBy={this.state.orderBy}
                sort={this.handleRequestSort}
              />
              <DataTableBody
                loading={loading}
                column={this.state.columns}
                data={data}
                page={page}
                isCompleted={this.props.url ? true : false}
                rowsPerPage={rowsPerPage}
                detailsPopup={this.detailsPopup}
                onClick={{
                  accept: this.acceptDumpster,
                  assign: this.assignDumpsterPopup,
                }}
                edit={this.props.edit}
                popupBtn={{
                  label: "View",
                  icon: "",
                  action: this.handleClickOpen,
                }}
              />
              {data.length > 0 && (
                <DataTablePagination
                  count={totalCount}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onChangePage={this.handleChangePage}
                  rowsPerPageOptions={[]}
                />
              )}
            </DataTable>
          </div>
        </div>
      </Fragment>
    );
  }
}
class DataTableBody2 extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    const {
      loading,
      page,
      rowsPerPage,
      data,
      column,
      edit,
      actions,
    } = this.props;
    return (
      <TableBody>
        {loading === false && data.length === 0 && (
          <TableRow>
            <TableCell align="center" colSpan={column.length}>
              No data Found!
            </TableCell>
          </TableRow>
        )}
        {loading === true && (
          <TableRow>
            <TableCell align="center" colSpan={9}>
              <CircularProgress />
            </TableCell>
          </TableRow>
        )}
        {loading === false &&
          data.length > 0 &&
          data.map((Dkey, index) => {
            return (
              <TableRow hover tabIndex={-1} key={Dkey._id}>
                <TableCell padding="none" align="left">
                  {++index + page * rowsPerPage}
                </TableCell>
                <TableCell padding="none" align="left">
                  {moment(Dkey["orderCreatedAt"]).format("YYYY-MM-DD")}
                </TableCell>
                <TableCell padding="none" align="left">
                  {Dkey.customerName}
                </TableCell>
                {/* <TableCell padding="none" align="left">
                  {Dkey.driverName}
                </TableCell>
                <TableCell padding="none" align="left" numeric>
                  {Dkey["distance"]}
                </TableCell> */}
                {/* <TableCell padding="none" align="left" numeric>
                  {!["none", "null", null, ""].includes(Dkey.promoCode)
                    ? Dkey.promoCode
                    : "N/A"}
                </TableCell>*/}
                <TableCell padding="none" align="left" numeric>
                  {Dkey.orderTotal ? Dkey.orderTotal.toFixed(2) : "0.00"}
                </TableCell>
                <TableCell padding="none" align="left">
                  <span className={`label 233 ${Dkey.paymentMethod}`}>
                    {Status[Dkey.paymentMethod]}
                  </span>
                </TableCell>

                <TableCell padding="none" align="left">
                  <span className={`label ${Dkey.orderStatus}`}>
                    {Status[Dkey.orderStatus]}
                  </span>
                </TableCell>
                <TableCell padding="none" align="left" key={index}>
                  {edit ? (
                    <Action actions={actions} id={Dkey._id} />
                  ) : (
                    <Button
                      className="bg-amber text-white CBmargin"
                      onClick={() => this.props.detailsPopup(true, Dkey._id)}
                    >
                      <i className="zmdi zmdi-eye" />
                    </Button>
                  )}
                </TableCell>
              </TableRow>
            );
          })}
      </TableBody>
    );
  }
}

export default ListAll;
