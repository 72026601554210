import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import AppBar from '@material-ui/core/AppBar';
import Avatar from '@material-ui/core/Avatar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import { Dropdown, DropdownMenu, DropdownToggle } from 'reactstrap';
import { BELOW_THE_HEADER, COLLAPSED_DRAWER, FIXED_DRAWER, HORIZONTAL_NAVIGATION, INSIDE_THE_HEADER } from 'constants/ActionTypes';
import { switchLanguage, toggleCollapsedNav } from 'actions/Setting';
import Menu from 'components/TopNav/Menu';
import UserInfoPopup from 'components/UserInfo/UserInfoPopup';
import UserInfo from 'components/UserInfo';
import { USER_TYPE } from '../../ApiUrl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';

import { Axios, Loading, toast } from 'components';
import { API_UPADTE_STORE_STATUS, API_URL, API_GET_NOTIFICATION_ITEMS } from '../../ApiUrl';

import io from 'socket.io-client';

import Cookies from 'js-cookie';

const socket = io.connect(API_URL, { secure: true });

{
  /* Header Component */
}
class Header extends React.Component {
  constructor() {
    super();
    this.state = {
      anchorEl: undefined,
      searchBox: false,
      searchText: '',
      mailNotification: false,
      userInfo: false,
      langSwitcher: false,
      appNotification: false,

      checkedB: Cookies.get('pharmacyStatus') == 'Online' ? true : false,
      store: Cookies.get('pharmacyStatus') == 'Online' ? 'Online' : 'Offline',
      notification: [],
      totalCount: '',
    };
  }

  componentWillMount() {
    this.getTableRecords();
    // this.setState({
    //   notification:
    //     localStorage.getItem("notification") !== null
    //       ? JSON.parse(localStorage.getItem("notification"))
    //       : [],
    // });

    console.log('i m call');
    socket.emit('restaurantsocket', { restaurantId: Cookies.get('_id') }, (data) => {
      console.log(211212, data);
    });

    socket.on('connection', (socket) => {
      console.log('i m connection');
      console.log('socket', socket);
    });

    socket.on('connect', () => {
      console.log(socket.connected); // true
    });
    socket.on('connect_error', (err) => {
      console.log(`connect_error due to ${err.message}`);
    });
    socket.on('disconnect', () => {
      console.log(socket.connected); // false
    });

    socket.on('order_restaurant_socket', (data) => {
      console.log(data, 'new message');
      this.getTableRecords();
    });
  }

  onAppNotificationSelect = () => {
    this.setState({
      appNotification: !this.state.appNotification,
    });
  };
  onMailNotificationSelect = () => {
    this.setState({
      mailNotification: !this.state.mailNotification,
    });
  };
  onLangSwitcherSelect = (event) => {
    this.setState({
      langSwitcher: !this.state.langSwitcher,
      anchorEl: event.currentTarget,
    });
  };
  onSearchBoxSelect = () => {
    this.setState({
      searchBox: !this.state.searchBox,
    });
  };
  onAppsSelect = () => {
    this.setState({
      apps: !this.state.apps,
    });
  };
  onUserInfoSelect = () => {
    this.setState({
      userInfo: !this.state.userInfo,
    });
  };
  handleRequestClose = () => {
    this.setState({
      langSwitcher: false,
      userInfo: false,
      mailNotification: false,
      appNotification: false,
      searchBox: false,
      apps: false,
    });
  };
  onToggleCollapsedNav = (e) => {
    const val = !this.props.navCollapsed;
    this.props.toggleCollapsedNav(val);
  };

  handleChange = (event) => {
    let status = 'Offline';
    if (this.state.store == 'Offline') {
      status = 'Online';
    }

    this.setState({
      ...this.state,
      [event.target.name]: event.target.checked,
      store: status,
    });
    this.getRecords(status);
  };

  getRecords = async (status) => {
    let body = { pharmacyStatus: status };

    Axios.post(API_UPADTE_STORE_STATUS, body)
      .then((response) => {
        response = response.data.data;

        this.setState({ pharmacyStatus: response.pharmacyStatus });
        Cookies.set('pharmacyStatus', response.pharmacyStatus);
      })
      .catch((err) => {
        //toast.error("No data Found.");
      });
    //this.setState({ loading: false });
  };

  getTableRecords = async () => {
    // console.log( "function start response");
    // const { storeId, fieldName, fieldValue, page, rowsPerPage } = this.state;
    this.setState({ loading: true });
    let body = {
      // storeId: storeId,
      orderBy: 'createdAt',
      order: -1,
      page: 1,
      limit: 5,
      pharmacyId: Cookies.get('_id'),
    };

    Axios.post(API_GET_NOTIFICATION_ITEMS, body)
      .then((response) => {
        // const res = response.data;

        console.log(response, 'response in header');

        this.setState((pre) => ({
          ...pre,
          totalCount: response.data ? response.data.count : 0,
          notification: response.data ? response.data.data : [],
          loading: false,
        }));
      })
      .catch((err) => {
        console.log(err, 'errrrr');
        toast.error('No data Found.');
      });

    this.setState({ loading: false });
  };

  updateSearchText(evt) {
    this.setState({
      searchText: evt.target.value,
    });
  }

  render() {
    const { drawerType, locale, navigationStyle, horizontalNavPosition } = this.props;
    const drawerStyle = drawerType.includes(FIXED_DRAWER) ? 'd-block d-xl-none' : drawerType.includes(COLLAPSED_DRAWER) ? 'd-block' : 'd-none';

    return (
      <AppBar
        className={`app-main-header custom-color ${
          navigationStyle === HORIZONTAL_NAVIGATION && horizontalNavPosition === BELOW_THE_HEADER ? 'app-main-header-top' : ''
        }`}
      >
        <Toolbar className="app-toolbar" disableGutters={false}>
          {/* Add spacing before "Pharmacy" */}
          <div style={{ width: '10px' }}></div>

          {navigationStyle === HORIZONTAL_NAVIGATION ? (
            <div className="d-block d-md-none pointer mr-3" onClick={this.onToggleCollapsedNav}>
              <span className="jr-menu-icon">
                <span className="menu-icon" />
              </span>
            </div>
          ) : (
            <>
              <IconButton className={`jr-menu-icon mr-3 ${drawerStyle}`} aria-label="Menu" onClick={this.onToggleCollapsedNav}>
                <span className="menu-icon" />
              </IconButton>
              <div class="header-title">
                {/* Hide Logo On Mobile Navbar */}
                {/*
                <img
                  src={require("assets/images/logo.png")}
                  alt="Jambo"
                  class="mobile-logo"
                  title="Jambo"
                />
                */}
                <h3>{USER_TYPE}</h3>
              </div>
            </>
          )}

          {/* Add spacing after "Pharmacy" */}
          <div style={{ width: '20px' }}></div>

          {/* Online/Offline Toggle Switch*/}
          <FormControlLabel
            control={<Switch checked={this.state.checkedB} onChange={this.handleChange} name="checkedB" color="primary" />}
            label={this.state.store}
          />

          <UserInfo data={this.state.notification} totalCount={this.state.totalCount} />

          {navigationStyle === HORIZONTAL_NAVIGATION && horizontalNavPosition === INSIDE_THE_HEADER && <Menu />}

          <ul className="header-notifications list-inline ml-auto">
            {navigationStyle === HORIZONTAL_NAVIGATION && (
              <li className="list-inline-item user-nav">
                <Dropdown className="quick-menu" isOpen={this.state.userInfo} toggle={this.onUserInfoSelect.bind(this)}>
                  <DropdownToggle className="d-inline-block" tag="span" data-toggle="dropdown">
                    <IconButton className="icon-btn size-30">
                      <Avatar alt="..." src={'https://via.placeholder.com/150x150'} className="size-30" />
                    </IconButton>
                  </DropdownToggle>

                  <DropdownMenu right>
                    <UserInfoPopup />
                  </DropdownMenu>
                </Dropdown>
              </li>
            )}
          </ul>

          <div className="ellipse-shape"></div>
        </Toolbar>
      </AppBar>
    );
  }
}

const mapStateToProps = ({ settings }) => {
  const { drawerType, locale, navigationStyle, horizontalNavPosition } = settings;
  return { drawerType, locale, navigationStyle, horizontalNavPosition };
};

export default withRouter(connect(mapStateToProps, { toggleCollapsedNav, switchLanguage })(Header));
