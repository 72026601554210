import React, { Component } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Typography from "@material-ui/core/Typography";

import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";

// import {SelectSearch} from "react-select-search";
import { useRef } from "react";

import {
  withRouter,
  toast,
  Grid,
  Row,
  Col,
  CardText,
  CardBody,
  CardHeader,
  Card,
  Avatar,
  Axios,
  Paper,
  DialogBox,
  Loading,
  moment,
  Status,
  ApiUrl,
  TEXTFIELD,
  noImage,
  SELECT,
  SEARCHFILTER,
  Format_Number,
} from "../../../../../components";
import { Fragment } from "react";
import MapLocation from "./LocationMap/MapLocation";

const NOIMAGE_PIC = require("assets/images/NoImageAvailable.png");
const {
  API_GET_ORDER_BY_ID,
  API_GET_ORDER_ACCEPT,
  API_GET_ORDER_REJECT,
  API_GET_ORDER_MARK,
  API_GET_ALL_NEAR_DRIVERS,
  API_ASSIGN_DRIVER,
  API_GET_ORDER_COMPLETE,
} = ApiUrl;

class Details extends Component {
  constructor(props) {
    super(props);
    const { open, id } = this.props;
    this.state = {
      open: open,
      acceptOpen: false,
      deleteConfirm: false,
      dumpId: id,
      details: {},
      companies: [],
      driversList: [],
      driverId: "",
      driverIdValue: "",
      companyId: "",
      loading: false,
      subTotal: 0,
      orderSubTotal: 0,
      serviceFeeAmount: 0,
      costTotal: 0,
      deliveryFee: 0,
      orderTotal: 0,
      insuranceFee: 0,
      imageOpen: false,
      imageView: null,
      errorState: false,
    };
  }

  componentDidMount = () => {
    this.setState({ loading: true });
    // console.log(this.props, 5555656);
    Axios.get(API_GET_ORDER_BY_ID + this.props.id)
      .then((response) => {
        response = response.data.data;
        this.setState({ details: response });
        this.setState({ loading: false });
      })
      .catch((error) => {
        this.setState({
          alertVisible: true,
          alertClass: "danger",
          alertMessage: "Order Not Found!",
        });
      });
    // GET ALL DRIVERS
    Axios.post(API_GET_ALL_NEAR_DRIVERS, { limit: 1000 })
      .then((res) => {
        let data = res.data.data;
        // const driversList =
        //   data &&
        //   data.map((i) => {
        //     return {
        //       [i._id]: `${i.firstName} ${i.lastName}` || i.name,
        //     };
        //   });
        const driversList =
          data &&
          data.map((i) => {
            return {
              value: i._id,
              name: `${i.firstName} ${i.lastName}`,
            };
          });
        this.setState({ driversList: driversList });
      })
      .catch((err) => {
        console.log("server error");
      });
  };

  // componentWillUnmount() {
  //   this.props.detailsPopup(false);
  // }

  handleClose = (open = null) => {
    if (open) {
      this.setState({ deleteConfirm: true });
    } else {
      this.props.detailsPopup(false);
    }
  };

  handleChange = (event) => {
    // console.log(event.target);
    this.setState({ [event.target.name]: event.target.value });
  };
  cancelConfirmBox = () => {
    this.setState({ deleteConfirm: true });
  };
  closeCancelConfirmBox = () => {
    this.setState({ deleteConfirm: false });
  };
  acceptPopup = (status) => {
    //console.log(status, 55665);
    this.setState({
      acceptOpen: status,
    });
  };
  acceptOrder = () => {
    // return;
    this.setState({ loading: true });
    const {
      subTotal,
      orderSubTotal,
      serviceFeeAmount,
      costTotal,
      deliveryFee,
      insuranceFee,
      orderTotal,
      dumpId,
      driverId,
      details,
    } = this.state;

    let data = { orderId: dumpId, driverId: driverId };
    if (details.orderType === "rx") {
      data = {
        ...data,
        orderSubTotal,
        serviceFeeAmount,
        deliveryFee,
        insuranceCoverage: insuranceFee,
        patientPay:
          Number(orderSubTotal) +
          Number(serviceFeeAmount) -
          Number(insuranceFee),
        costTotal: Number(orderSubTotal) + Number(serviceFeeAmount),
        orderSubTotal:
          Number(orderSubTotal) +
          Number(serviceFeeAmount) +
          Number(deliveryFee),
        orderTotal:
          Number(orderSubTotal) +
          Number(serviceFeeAmount) -
          Number(insuranceFee),
      };
    }

    // return;
    Axios.post(API_GET_ORDER_ACCEPT, data)
      .then((response) => {
        const { status, data, message } = response.data;
        if (status === "success") {
          this.setState((pre) => ({
            ...pre,
            details: {
              ...pre.details,
              insuranceFee,
              orderStatus: data.data.orderStatus,
              orderSubTotal,
              serviceFeeAmount,
              deliveryFee,
              ...data.data,
            },
            acceptOpen: false,
            loading: false,
          }));
          return;
        }
        toast.error(message);
        this.setState({ loading: false });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  rejectOrder = () => {
    this.setState({ loading: true });
    Axios.post(API_GET_ORDER_REJECT, { orderId: this.props.id })
      .then((response) => {
        const { status, data, message } = response.data;
        if (status === "success") {
          toast.success(message);
          this.setState((pre) => ({
            ...pre,
            details: {
              ...pre.details,
              orderStatus: data.data.orderStatus,
            },
            loading: false,
          }));
          return;
        }
        toast.error(message);
        this.setState({ loading: false });
      })
      .catch((error) => {
        this.setState({
          alertVisible: true,
          alertClass: "danger",
          alertMessage: "Order Not Found!",
          loading: true,
        });
      });
  };
  markOrderReady = () => {
    this.setState({ loading: true });
    Axios.post(API_GET_ORDER_MARK, {
      orderId: this.props.id,
      orderType: this.state.orderType,
    })
      .then((response) => {
        toast.success(response.data.message);
        response = response.data.data;

        //  console.log(response);
        this.props.detailsPopup(false);
        this.setState({ details: response.data });
        this.setState({ loading: false });
      })
      .catch((error) => {
        this.setState({
          alertVisible: true,
          alertClass: "danger",
          alertMessage: "Order Not Found!",
        });
      });
  };

  markOrderComplete = () => {
    this.setState({ loading: true });
    Axios.post(API_GET_ORDER_COMPLETE, { orderId: this.props.id })
      .then((response) => {
        toast.success(response.data.message);
        response = response.data.data;

        //  console.log(response);
        this.setState({ open: false });
        this.setState({ loading: false });
      })
      .catch((error) => {
        this.setState({
          alertVisible: true,
          alertClass: "danger",
          alertMessage: "Order Not Found!",
        });
      });
  };

  onDismiss = () => {
    this.setState({
      alertVisible: false,
      alertMessage: "",
      alertClass: "success",
    });
  };

  showImage = (e, image) => {
    e.preventDefault();
    this.setState({
      imageOpen: true,
      imageView: image,
    });
  };
  closeImage = (e) => {
    e.preventDefault();
    this.setState({
      imageOpen: false,
      imageView: null,
    });
  };

  assignDriver = (e) => {
    e.preventDefault();
    this.setState({ loading: true });
    const { dumpId} = this.state;

    let data = { orderId: dumpId};
    Axios.post(API_ASSIGN_DRIVER, data)
      .then((response) => {
          let res = response.data.data;
          //const { status, data, message } = response.data;
          if (response.data.status === "success") {
            //  console.log(res);
            toast.success(response.data.message);
            this.setState({ loading: false, acceptOpen: false, errorState: false });

            this.props.detailsPopup(false);
            this.props.history.push("/app/orders/all");
          } else {
            toast.error(response.data.message);
            this.setState({ loading: false, errorState: true });
          }
        })
      .catch((error) => {
          this.setState({ loading: false, errorState: true });
          toast.success("server error");
          this.setState({
            alertVisible: true,
            alertClass: "danger",
            alertMessage: "Order Not Found!",
          });
        });
    
  };

  // search filter start

  handleSelect = (e) => {
    const InputValue = e.target.value;
    const { driversList } = this.state;
    const DataID = driversList.find((item) => item.name === e.target.value);
    console.log(DataID, "DataIDDataID");

    this.setState((pre) => ({
      ...pre,
      driverId: InputValue,
      driverIdValue: DataID ? DataID.value : "",
    }));
  };
  render() {
    const {
      open,
      details,
      loading,
      driverId,
      companies,
      companyId,
      deleteConfirm,
      subTotal,
      orderSubTotal,
      deliveryFee,
      insuranceFee,
      driversList,
      serviceFeeAmount,
      imageOpen,
      imageView,
    } = this.state;
    // console.log(details);
    console.log(driversList, "7777777777");
    const {
      driverRefId,
      customer,
      pharmacyLocation,
      billingDetails,
      driverLocation,
    } = details;
    let image = ["", null, undefined, "none"];
    // console.log(details, 66666);
    let cnt = 0;

    console.log(driversList, "driversList");
    return (
      <div>
        <Dialog
          maxWidth="lg"
          fullWidth={true}
          open={open}
          onClose={() => this.handleClose()}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle
            id="form-dialog-title"
            onClose={() => this.handleClose()}
          >
            Orders Details{" "}
          </DialogTitle>
          <DialogContent>
            {loading && (
              <div className="text-center w-100">
                <Loading />
              </div>
            )}
            <Dialog
              open={imageView}
              fullWidth
              maxWidth="sm"
              onClose={(e) => this.closeImage(e, false)}
            >
              <DialogContent>
                <img
                  src={imageView}
                  style={{ width: "100%", height: "100%" }}
                />
              </DialogContent>
              <DialogActions>
                <Button onClick={(e) => this.closeImage(e, false)}>
                  Close
                </Button>
              </DialogActions>
            </Dialog>

            <Dialog
              open={this.state.acceptOpen}
              onClose={(e) => this.acceptPopup(false)}
            >
              <DialogTitle>Accept Order</DialogTitle>
              <DialogContent>
                {details.orderType !== "otc" && (
                  <Fragment>
                    <Row>
                      <Col md={6} xs={12}>
                        <TEXTFIELD
                          name="orderSubTotal"
                          value={orderSubTotal}
                          label="Cost"
                          type="number"
                          fullWidth
                          onChange={this.handleChange}
                          variant="standard"
                        />
                      </Col>
                      <Col md={6} xs={12}>
                        <TEXTFIELD
                          name="serviceFeeAmount"
                          value={serviceFeeAmount}
                          label="Fee"
                          type="number"
                          fullWidth
                          onChange={this.handleChange}
                          variant="standard"
                        />
                      </Col>

                      <Col md={4} xs={12}>
                        <TEXTFIELD
                          name="insuranceFee"
                          value={insuranceFee}
                          label="Insurance Coverage"
                          type="number"
                          fullWidth
                          onChange={this.handleChange}
                          variant="standard"
                        />
                      </Col>
                    </Row>
                    <Row>
                      <CardText className="col-sm-8">
                        <b>Total </b>:CAD
                        {(
                          Number(orderSubTotal || 0) +
                          Number(serviceFeeAmount || 0)
                        ).toFixed(2)}
                      </CardText>
                      <CardText className="col-sm-4">
                        <b>Patient Pays</b>:CAD
                        {(
                          Number(orderSubTotal || 0) +
                          Number(deliveryFee || 0) +
                          Number(serviceFeeAmount || 0) -
                          Number(insuranceFee || 0)
                        ).toFixed(2)}
                      </CardText>
                    </Row>
                  </Fragment>
                )}
              </DialogContent>
              <DialogActions>
                <Button onClick={(e) => this.acceptPopup(false)}>Close</Button>
                <Button onClick={(e) => this.acceptOrder()}>Accept</Button>
              </DialogActions>
            </Dialog>

            <Grid container spacing={3} style={{ marginTop: 20 }}>
              <Grid item xs={12}>
                <Paper>
                  <Card className="details Driver_profile">
                    {billingDetails && (
                      <CardBody className="CcardPos row text-left col-sm-12">
                        <h3 className="card-title col-sm-12">Order Details</h3>
                        <CardText className="col-sm-6">
                          <b>Order ID: </b> {details.customOrderId}
                        </CardText>
                        <CardText className="col-sm-6">
                          <b>Name: </b> {details.customerName}
                        </CardText>
                        <CardText className="col-sm-6">
                          <b>Mobile Number: </b> 
                          {/* details.customerMobile */}
                          {details.customerMobile.slice(0, 3) + '-' + details.customerMobile.slice(3, 6) + '-' + details.customerMobile.slice(6, 10)} 
                        </CardText>
                        <CardText className="col-sm-6">
                          <b>Address: </b> {" "}
                          {(details.billingDetails &&
                            details.billingDetails.address) ||
                            ""}
                        </CardText>
                        <CardText className="col-sm-6 text-capitalize">
                          <b>Service Type: </b>
                          <span className={`label ${details.serviceType}`}>
                            {details.serviceType}
                          </span>
                        </CardText>
                        <CardText className="col-sm-6">
                          <b>Order Type: </b>
                          <span className={`label ${details.orderType}`}>
                            {Status[details.orderType]}
                          </span>
                        </CardText>
                        <CardText className="col-sm-6">
                          <b>Payment Type: </b>
                          <span className={`label ${details.paymentMethod}`}>
                            {Status[details.paymentMethod]}
                          </span>
                        </CardText>
                        <CardText className="col-sm-4">
                          <b>Payment Status: </b>
                          <span className={`label ${details.paymentStatus}`}>
                            {details.paymentStatus.charAt(0).toUpperCase() + details.paymentStatus.slice(1)}
                          </span>
                        </CardText>
                        {details.tip === "yes" && (
                          <Fragment>
                            <CardText className="col-sm-4">
                              <b>Tip: </b> ${details.tip || 0}
                            </CardText>
                            <CardText className="col-sm-4">
                              <b>Tip Status: </b>
                              <span
                                className={`label ${details.tipPaymentStatus}`}
                              >
                                {Status[details.tipPaymentStatus]}
                              </span>
                            </CardText>
                          </Fragment>
                        )}
                        {details.serviceType === "delivery" && (
                          <>
                            {/* <CardText className="col-sm-6">
                              <b>Driver Assigned</b>:
                              <span
                                className={`label ${details.driverAssigned}`}
                              >
                                {Status[details.driverAssigned]}
                              </span>
                            </CardText> */}

                            <CardText className="col-sm-6">
                              <b>Driver Request Status: </b>
                              <span
                                className={`label ${details.requestSentDriver}`}
                              >
                                {Status[details.requestSentDriver]}
                              </span>
                            </CardText>
                          </>
                        )}
                        <CardText className="col-sm-6">
                          <b>Order Created At: </b>{" "}
                          <span style={{fontWeight: "bold"}}>
                            {moment(details.orderCreatedAt).format("LLL")}
                          </span>
                        </CardText>
                        {details.serviceType === "pickup" && (
                          <Fragment>
                            <CardText className="col-sm-6">
                              Pick Up Date At:{" "}
                              {/* <span>
                                {moment(details.scheduleDate).format(
                                  "DD-MM-YYYY"
                                )}
                              </span> */}
                              <span>{details.scheduleDate}</span>
                            </CardText>
                            <CardText className="col-sm-6">
                              Pick Up Time At:{" "}
                              <span>{details.scheduleTime}</span>
                            </CardText>
                          </Fragment>
                        )}
                        {details.orderType !== "otc" && (
                          <Fragment>
                            <CardText className="col-sm-6">
                              <b>Prescription Type: </b> {" "}
                              <span
                                className={`label ${details.prescriptionType}`}
                              >
                                {Status[details.prescriptionType]}
                              </span>
                            </CardText>
                            {details.prescriptionNumbers && (
                              <CardText className="col-sm-6">
                                <b>Prescription Number: </b> {" "}
                                {details.prescriptionNumbers}
                              </CardText>
                            )}
                          </Fragment>
                        )}
                        <CardText className="col-sm-6">
                          <b>Note: </b> {details.orderInstructions}
                        </CardText>

                        <CardText className="col-sm-6">
                          <b>Status: </b> {" "}
                          <span className={`label ${details.orderStatus}`}>
                            {Status[details.orderStatus]}
                          </span>
                        </CardText>
                      </CardBody>
                    )}
                  </Card>
                </Paper>
              </Grid>
              {details.orderType === "rx" && (
                <Grid item xs={12}>
                  <Paper>
                    <Card className="details Driver_profile">
                      <CardBody className="CcardPos row text-left col-sm-12">
                        <h3 className="card-title col-sm-12">
                          Payment Details{" "}
                        </h3>
                        <Row style={{"margin-left": "0px"}}>
                          <CardText className="col-sm-6">
                            <b>Total Cost: </b>
                            {Format_Number(details.costTotal)}
                          </CardText>
                          <CardText className="col-sm-6">
                            <b>Service Fee: </b>
                            {Format_Number(details.serviceFeeAmount)}
                          </CardText>
                          <CardText className="col-sm-6">
                            <b>Delivery Fees: </b>
                            {Format_Number(details.deliveryFee)}
                          </CardText>
                          <CardText className="col-sm-6">
                            <b>Pharmacy Service Fee: </b>
                            {Format_Number(details.pharmacyServiceFeeAmount)}
                          </CardText>

                          <CardText className="col-sm-6">
                            <b>Insurance Amount: </b> -
                            {Format_Number(details.insuranceCoverage)}
                          </CardText>
                          <CardText className="col-sm-6">
                            <b>Patient Total: </b>
                            {Format_Number(details.patientPay)}
                          </CardText>
                        </Row>
                      </CardBody>
                    </Card>
                  </Paper>
                </Grid>
              )}
              <Grid item xs={12}>
                <Row>
                  {details.orderType === "rx" && (
                    <CardText className="col-sm-4">
                      <Card className={"card-box"}>
                        <CardHeader>Prescription Receipt</CardHeader>
                        <CardActionArea>
                          <CardMedia
                            component="img"
                            alt="Contemplative Reptile"
                            height="140"
                            image={
                              details.prescription &&
                              details.prescription.prescriptionImage
                            }
                            title="Contemplative Reptile"
                          />
                        </CardActionArea>
                        <CardActions>
                          <Button
                            size="small"
                            color="primary"
                            onClick={(e) =>
                              this.showImage(
                                e,
                                details.prescription.prescriptionImage
                              )
                            }
                          >
                            View
                          </Button>
                        </CardActions>
                      </Card>
                    </CardText>
                  )}

                  {details.customerDetails && (
                    <Fragment>
                      <CardText className="col-sm-4">
                        <Card className={"card-box"}>
                          <CardHeader>Insurance Front</CardHeader>
                          <CardActionArea>
                            <CardMedia
                              component="img"
                              alt="Contemplative Reptile"
                              height="140"
                              image={
                                !noImage.includes(
                                  details.customerDetails.insuranceFront
                                )
                                  ? details.customerDetails.insuranceFront
                                  : NOIMAGE_PIC
                              }
                              title="Contemplative Reptile"
                            />
                          </CardActionArea>
                          <CardActions>
                            <Button
                              size="small"
                              color="primary"
                              onClick={(e) =>
                                this.showImage(
                                  e,
                                  !noImage.includes(
                                    details.customerDetails.insuranceFront
                                  )
                                    ? details.customerDetails.insuranceFront
                                    : NOIMAGE_PIC
                                )
                              }
                            >
                              View
                            </Button>
                          </CardActions>
                        </Card>
                      </CardText>

                      <CardText className="col-sm-4">
                        <Card className={"card-box"}>
                          <CardHeader>Insurance Back</CardHeader>
                          <CardActionArea>
                            <CardMedia
                              component="img"
                              alt="Contemplative Reptile"
                              height="140"
                              image={
                                !noImage.includes(
                                  details.customerDetails.insuranceBack
                                )
                                  ? details.customerDetails.insuranceBack
                                  : NOIMAGE_PIC
                              }
                              title="Contemplative Reptile"
                            />
                          </CardActionArea>
                          <CardActions>
                            <Button
                              size="small"
                              color="primary"
                              onClick={(e) =>
                                this.showImage(
                                  e,
                                  !noImage.includes(
                                    details.customerDetails.insuranceBack
                                  )
                                    ? details.customerDetails.insuranceBack
                                    : NOIMAGE_PIC
                                )
                              }
                            >
                              View
                            </Button>
                          </CardActions>
                        </Card>
                      </CardText>

                      <CardText className="col-sm-4">
                        <Card className={"card-box"}>
                          <CardHeader>OHIP Card Image</CardHeader>
                          <CardActionArea>
                            <CardMedia
                              component="img"
                              alt="Contemplative Reptile"
                              height="140"
                              image={
                                !noImage.includes(
                                  details.customerDetails.ohipCardImage
                                )
                                  ? details.customerDetails.ohipCardImage
                                  : NOIMAGE_PIC
                              }
                              title="Contemplative Reptile"
                            />
                          </CardActionArea>
                          <CardActions>
                            <Button
                              size="small"
                              color="primary"
                              onClick={(e) =>
                                this.showImage(
                                  e,
                                  !noImage.includes(
                                    details.customerDetails.ohipCardImage
                                  )
                                    ? details.customerDetails.ohipCardImage
                                    : NOIMAGE_PIC
                                )
                              }
                            >
                              View
                            </Button>
                          </CardActions>
                        </Card>
                      </CardText>
                    </Fragment>
                  )}

                  {details &&
                  details.orderStatus === "completed" &&
                  details.serviceType === "delivery" ? (
                    <CardText className="col-sm-4">
                      <Card className={"card-box"}>
                        <CardHeader>Delivery Confirmation Photo from Driver</CardHeader>
                        <CardActionArea>
                          <CardMedia
                            component="img"
                            alt="Contemplative Reptile"
                            height="140"
                            image={
                              details && details.orderUrl
                                ? details.orderUrl
                                : NOIMAGE_PIC
                            }
                            title="Contemplative Reptile"
                          />
                        </CardActionArea>
                        <CardActions>
                          <Button
                            size="small"
                            color="primary"
                            onClick={(e) =>
                              this.showImage(
                                e,
                                details && details.orderUrl
                                  ? details.orderUrl
                                  : NOIMAGE_PIC
                              )
                            }
                          >
                            View
                          </Button>
                        </CardActions>
                      </Card>
                    </CardText>
                  ) : (
                    ""
                  )}
                </Row>
              </Grid>
              {details.orderType === "otc" && (
                <Grid item xs={12}>
                  <Paper className={"classes.paper"}>
                    <Card className="details Driver_profile">
                      <CardBody className="CcardPos row text-left col-sm-12">
                        <h3 className="card-title col-sm-12">Item Details</h3>
                      </CardBody>
                    </Card>
                    <div className="table-responsive-material col-sm-12">
                      <table className="default-table table-unbordered table table-sm table-hover">
                        <thead className="th-border-b">
                          <tr>
                            <th>#</th>
                            <th>Name</th>
                            <th>Price</th>
                            <th>Discount</th>
                            <th>Quantity</th>
                            <th>Total</th>
                          </tr>
                        </thead>
                        <tbody>
                          {details.items &&
                            details.items.map((n, key) => {
                              let price = n.itemPrice;
                              let discount = n.itemDiscountPrice;
                              let coun = ++cnt;
                              return (
                                <tr tabIndex={-1} key={key}>
                                  <td>{coun}</td>
                                  <td>
                                    <b>{n.itemName} </b>
                                  </td>
                                  <td>{Format_Number(price)}</td>
                                  <td>{Format_Number(discount)}</td>
                                  <td>
                                    <b>{n.quantity} </b>
                                  </td>
                                  <td>{Format_Number(n.itemTotal)}</td>
                                </tr>
                              );
                            })}
                        </tbody>
                      </table>
                    </div>
                    <div
                      className="order-total pr-3 pl-3"
                      style={{ textAlign: "right" }}
                    >
                      <hr />
                      {/* <p>
                        Sub-Total : $
                        {details.orderTotal
                          ? details.orderSubTotal.toFixed(2)
                          : "0.00"}
                      </p> */}
                      {details.salesTaxAmount > 0 && (
                        <p>
                          Sales Tax Amount :
                          {Format_Number(details.salesTaxAmount)}
                        </p>
                      )}
                      {details.serviceFeeAmount > 0 && (
                        <p>
                          Service Fee Amount :
                          {Format_Number(details.serviceFeeAmount)}
                        </p>
                      )}
                      {details.deliveryFee > 0 && (
                        <p>
                          Delivery Fee :{Format_Number(details.deliveryFee)}
                        </p>
                      )}
                      {details.promoCodeCost > 0 && (
                        <p>
                          Total Discount :{Format_Number(details.promoCodeCost)}
                        </p>
                      )}

                      <p>Grand-Total :{Format_Number(details.orderTotal)}</p>
                    </div>
                  </Paper>
                </Grid>
              )}

{details &&
              details.orderStatus === "confirmed" &&
              details.orderReadyStatus === "no" &&
              details.serviceType === "pickup" ? (
                <Grid item xs={12}>
                  <Paper>
                    <Card className=""></Card>
                    <CardText className="col-sm-3">
                      <Button
                        variant="raised"
                        color="primary"
                        margin="normal"
                        className="jr-btn jr-btn-label left CBmargin CBmarginT"
                        type="submit"
                        onClick={(e) => this.markOrderReady(e)}
                      >
                        <span className="nowrap">Mark Ready</span>
                      </Button>
                    </CardText>
                  </Paper>
                </Grid>
              ) : (
                ""
              )}
            
              {details &&
              details.orderStatus === "confirmed" &&
              details.orderReadyStatus === "yes" &&
              details.serviceType === "pickup" ? (
                <Grid item xs={12}>
                  <Paper>
                    <Card className=""></Card>
                    <CardText className="col-sm-3">
                      <Button
                        variant="raised"
                        color="primary"
                        margin="normal"
                        className="jr-btn jr-btn-label left CBmargin CBmarginT"
                        type="submit"
                        onClick={(e) => this.markOrderComplete(e)}
                      >
                        <span className="nowrap">Mark as Picked</span>
                      </Button>
                    </CardText>
                  </Paper>
                </Grid>
              ) : (
                ""
              )}

              {details &&
              details.orderStatus === "confirmed" &&
              details.requestSentDriver === "pending" &&
              details.serviceType === "delivery" && details.paymentStatus === "success" ? (
                <Grid item xs={12}>
                  <Paper>
                    <Card className="">
                      <CardBody className="CcardPos row text-left col-sm-12"></CardBody>
                      {details.orderStatus === "confirmed" && (
                        <Fragment>
                          <Row>
                            <CardText className="col-sm-3">
                              <Button
                                variant="raised"
                                color="primary"
                                margin="normal"
                                className="jr-btn jr-btn-label left CBmargin CBmarginT"
                                type="submit"
                                onClick={(e) => this.assignDriver(e)}
                                disabled={this.state.loading}
                              >
                                <span className="nowrap">
    {this.state.loading ? "Loading..." : "Assign"}
  </span>
</Button>
{this.state.errorState && (
  <span style={{ color: 'red', marginLeft: '10px' }}>Error occurred.</span>
)}
                            </CardText>
                          </Row>
                        </Fragment>
                      )}
                    </Card>
                  </Paper>
                </Grid>
              ) : (
                ""
              )}
              {details &&
              details.orderStatus === "confirmed" &&
              details.requestSentDriver === "pending" &&
              details.serviceType === "delivery" && details.paymentStatus === "pending" ? (
                <Grid item xs={12}>
                  <Paper>
                    <Card className="">
                      <CardBody className="CcardPos row text-left col-sm-12"></CardBody>
                      {details.orderStatus === "confirmed" && (
                        <Fragment>
                          <Row>
                            <CardText className="col-sm-3">
                                <b>Waiting For Payment - </b>
                                <span className="nowrap">DO NOT Assign</span>
                            </CardText>
                          </Row>
                        </Fragment>
                      )}
                    </Card>
                  </Paper>
                </Grid>
              ) : (
                ""
              )}

              {details && details.orderStatus === "pending" && (
                <Grid item xs={12}>
                  <Paper>
                    <Card className="">
                      <CardBody className="CcardPos row text-left col-sm-12">
                        {details.orderStatus === "pending" && (
                          <Fragment>
                            {/* <CardText className="col-sm-3">
                              <SELECT
                                label="Near Drivers"
                                value={driverId}
                                name={"driverId"}
                                onChange={this.handleChange}
                                data={driversList}
                                // errors={errors}
                              />
                            </CardText> */}
                            <CardText className="col-sm-3">
                              <Button
                                variant="raised"
                                color="primary"
                                margin="normal"
                                className="jr-btn jr-btn-label left CBmargin CBmarginT"
                                type="submit"
                                onClick={() => this.acceptPopup(true)}
                              >
                                <span className="nowrap">Accept</span>
                              </Button>
                            </CardText>
                            <CardText className="col-sm-3">
                              <Button
                                variant="raised"
                                color="primary"
                                margin="normal"
                                className="jr-btn jr-btn-label left CBmargin CBmarginT"
                                type="submit"
                                onClick={() => this.rejectOrder()}
                              >
                                <span className="nowrap">Reject</span>
                              </Button>
                            </CardText>
                          </Fragment>
                        )}
                      </CardBody>
                    </Card>
                  </Paper>
                </Grid>
              )}

              {details.driverRefId && (
                <Grid item xs={12}>
                  <Paper className={"classes.paper"}>
                    <Card className="details Driver_profile">
                      <CardBody className="CcardPos row text-left col-sm-12">
                        <h3 className="card-title col-sm-12">Driver Details</h3>
                        <CardText className="col-sm-3">
                          Name:{" "}
                          <span>{`${driverRefId.firstName} ${driverRefId.lastName}`}</span>
                        </CardText>
                        <CardText className="col-sm-3">
                          Contact: <span>{driverRefId.mobileNumber}</span>
                        </CardText>
                        <CardText className="col-sm-3">
                          Email: <span>{driverRefId.email}</span>
                        </CardText>
                      </CardBody>
                    </Card>
                  </Paper>
                </Grid>
              )}
              {details.driverRefId && driverRefId.selectedCar !== undefined && (
                <Grid item xs={12}>
                  <Paper className={"classes.paper"}>
                    <Card className="details Driver_profile">
                      <CardBody className="CcardPos row text-left col-sm-12">
                        <h3 className="card-title col-sm-12">
                          Selected Car Details
                        </h3>
                        <CardText className="col-sm-2">
                          <img
                            style={{ width: 60, height: 60 }}
                            src={
                              driverRefId.selectedCar.carImage &&
                              !image.includes(driverRefId.selectedCar.carImage)
                                ? driverRefId.selectedCar.carImage
                                : require("assets/images/no-image.png")
                            }
                          />
                        </CardText>
                        <CardText className="col-sm-2">
                          Name:{" "}
                          <span>
                            <br />
                            {driverRefId.selectedCar.carName}
                          </span>
                        </CardText>
                        <CardText className="col-sm-2">
                          Color:{" "}
                          <span>
                            <br />
                            {driverRefId.selectedCar.carColor}
                          </span>
                        </CardText>
                        <CardText className="col-sm-2">
                          Type:{" "}
                          <span>
                            <br />
                            {driverRefId.selectedCar.carType}
                          </span>
                        </CardText>
                        <CardText className="col-sm-2">
                          Plate Number:{" "}
                          <span>
                            <br />
                            {driverRefId.selectedCar.plateNumber}
                          </span>
                        </CardText>
                      </CardBody>
                    </Card>
                  </Paper>
                </Grid>
              )}
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              variant="raised"
              color="primary"
              margin="normal"
              style={{ width: "10%" }}
              className="jr-btn jr-btn-label left CBmargin CBmarginT"
              type="submit"
              onClick={() => this.handleClose()}
            >
              <span className="nowrap">Close</span>
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}
export default withRouter(Details);
