import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom';

import asyncComponent from '../../../util/asyncComponent';

const Recurring = ({ match }) => {
    return (
        <div className="app-wrapper">
            <Switch>
                <Redirect exact from={`${match.url}/`} to={`${match.url}/all`} />

                <Route path={`${match.url}/all`} component={asyncComponent(() => import('./routes/all'))} />
            </Switch>
        </div>
    )
}

export default Recurring