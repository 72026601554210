import React, { useState } from 'react';

import { PASS_REGEX } from '../ApiUrl';

import { TEXTFIELD, Col, SUBMIT, Axios, toast, PASSWORDFIELD } from '../components';

import { API_FORGOTPASSWORD, API_RESETPASSWORD } from '../ApiUrl';

const ForgetPassword = (props) => {
  const [OTP, setOTP] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [showReset, setShowReset] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState('');

  function forgot(e) {
    e.preventDefault();
    setIsLoading(true);

    Axios.post(API_FORGOTPASSWORD, { email })
      .then((res) => {
        if (res.data.message !== 'OTP Send Successfully') {
          throw new Error(res.data.message);
        }
        toast.success('OTP Send To Email Successfully.');
        setShowReset(true);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        toast.error(`${err}`);
      });
  }

  function reset(e) {
    e.preventDefault();
    setIsLoading(true);

    if (password.length < 1) {
      setIsLoading(false);
      return toast.error('Password Required');
    } else {
      if (!PASS_REGEX.test(password)) {
        setIsLoading(false);
        return toast.error('password should be at least 8 characters, one uppercase, lowercase, special character, numeric value.');
      }
      if (password !== confirmPassword) {
        setIsLoading(false);
        return toast.error('Confirm Password should be match to Password');
      }
    }

    Axios.post(API_RESETPASSWORD, { email, password, confirmPassword, OTP })
      .then((res) => {
        if (res.data.message !== 'Password Reset Successfully') {
          throw new Error(res.data.message);
        }
        toast.success('Password Reset Successfully.');
        props.changeView('login');

        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        toast.error(`${err}`);
      });
  }
  return (
    <div className="app-login-content">
      {!showReset ? (
        <form onSubmit={forgot}>
          <h1>Forgot Your Password</h1>
          <p>Type in your email and we'll send you a key to reset your password</p>
          <Col xm="12">
            <TEXTFIELD
              label="Enter Email"
              fullWidth
              name="email"
              onChange={(event) => {
                setEmail(event.target.value);
              }}
              value={email}
              errors={{}}
            />
          </Col>
          <Col md="6" xs="12">
            <SUBMIT style={{ backgroundColor: 'green', color: 'white' }} title="Send reset OTP" disabled={isLoading} />
          </Col>
        </form>
      ) : (
        <form onSubmit={reset}>
          <h1>Reset Your Password</h1>
          <p>Type in your new password to reset the old password</p>
          {/* <div style={{display:"none"}}>
                        <Col xm="12">
                            <TEXTFIELD label="Enter Email" fullWidth name="email" onChange={(event) => { setEmail(event.target.value) }} value={email} errors={{}} />
                        </Col>
                    </div> */}
          <Col xm="12">
            <PASSWORDFIELD
              label="Password"
              fullWidth
              name="password"
              onChange={(event) => {
                setPassword(event.target.value);
              }}
              value={password}
              errors={{}}
            />
          </Col>
          <Col xm="12">
            <PASSWORDFIELD
              label="Confirm Password"
              fullWidth
              name="confirmPassword"
              onChange={(event) => {
                setConfirmPassword(event.target.value);
              }}
              value={confirmPassword}
              errors={{}}
            />
          </Col>
          <Col xm="12">
            <TEXTFIELD
              label="Enter OTP"
              fullWidth
              name="otp"
              onChange={(event) => {
                setOTP(event.target.value);
              }}
              value={OTP}
              errors={{}}
            />
          </Col>
          <Col>
            <p style={{ color: '#0A7FC1', cursor: 'pointer' }} onClick={() => setShowReset(false)}>
              did not receive otp?
            </p>
          </Col>
          <Col md="6" xs="12">
            <SUBMIT style={{ backgroundColor: 'green', color: 'white' }} title="Reset Password" disabled={isLoading} />
          </Col>
        </form>
      )}
    </div>
  );
};

export default ForgetPassword;
