import React from "react";
import Avatar from "@material-ui/core/Avatar";
import { connect } from "react-redux";
import Menu from "@material-ui/core/Menu";
import { NavLink, withRouter } from "react-router-dom";
import MenuItem from "@material-ui/core/MenuItem";
import { userSignOut } from "actions/Auth";
import IntlMessages from "util/IntlMessages";
import Chip from "@material-ui/core/Chip";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";

import Cookies from "js-cookie";

class UserInfo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      anchorEl: null,
      anchorEl2: null,
      open: false,
      open2: false,
      openLogout: false,
      notification: props.data,
      totalCount: props.totalCount,
    };
  }

  // componentDidMount(){}

  handleClick = (event) => {
    this.setState({ open: true, anchorEl: event.currentTarget });
  };

  handleClick2 = (event) => {
    this.setState({ open2: true, anchorEl2: event.currentTarget });
  };

  handleRequestClose = () => {
    this.setState({ open: false });
  };

  handleRequestClose2 = () => {
    this.setState({ open2: false });
  };

  logoutConfirmation = (state) => {
    this.setState({ openLogout: state });
  };

  userSignOut = () => {
    Cookies.remove('token');
    Cookies.remove('_id');
    Cookies.remove('name');
    Cookies.remove('role');
    Cookies.remove('categories');
    Cookies.remove('user_id');
    Cookies.remove('restaurantStatus');
    Cookies.remove('featureAccess');
    Cookies.remove('province');

    this.props.history.push("/signin");
  };
  //   componentDidMount(){
  //     this.setState(JSON.parse(localStorage.getItem("notification")))

  // console.log(localStorage.getItem("notification"), "get")

  // setInterval(() => {
  //   const cartlist = JSON.parse(localStorage.getItem("cart")) || [];
  //   this.setState((pre) => ({ cartList: cartlist.length }));
  // }, 500);
  //   }
  render() {
    console.log(this.props, "props");
    // const { notification } = this.state;

    const Notificationdata = this.props.data;

    // console.log(notification, "notificationnotificationnotification")

    const totalnotification = this.props.totalCount;
    const { openLogout } = this.state;
    return (
      <div className="user-profile d-flex flex-row align-items-center" style={{"cursor": "pointer"}}>
        <Dialog
          open={openLogout}
          onClose={() => this.logoutConfirmation(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          {/* Logout Confirmation Window */}
          <DialogTitle id="alert-dialog-title">Logout Confirmation</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Do you want to Log Out of the Pharmacy Dashboard?
            </DialogContentText>
          </DialogContent>
          <DialogActions>

            {/* Log Out Button */}
            <Button
              onClick={() => this.userSignOut()}
              color="primary"
              autoFocus
            >
              Log Out
            </Button>

            {/* Cancel Button */}
            <Button
              onClick={() => this.logoutConfirmation(false)}
              color="primary"
            >
              Cancel
            </Button>

          </DialogActions>
        </Dialog>
        <div class=""></div>
        {/* notification start */}

        <div
          className="user-detail notificationicon"
          onClick={this.handleClick2}
        >
          <i class="zmdi zmdi-notifications-active"></i>
          <span className="not-count ">{totalnotification}</span>
          {/* <i class="zmdi zmdi-caret-down zmdi-hc-fw align-middle"></i> */}
        </div>

        {/* If there are more than 0 notifications, display a link when clicked */}
        {totalnotification > 0 ? (
          <Menu
            className="user-info"
            id="simple-menu"
            anchorEl={this.state.anchorEl2}
            open={this.state.open2}
            onClose={this.handleRequestClose2}
            PaperProps={{
              style: {
                minWidth: 200,
                paddingTop: 0,
                paddingBottom: 0,
              },
            }}
          >
            {Notificationdata &&
              Notificationdata.slice(0, totalnotification).map((n) => {
                return (
                  <MenuItem
                    onClick={() => {
                      this.handleRequestClose2();
                    }}
                  >
                    <NavLink
                      onClick={this.handleRequestClose2}
                      to="/app/orders/all"
                    >
                      {n.message}
                    </NavLink>
                  </MenuItem>
                );
              })}

            <NavLink
              onClick={this.handleRequestClose2}
              className="see_more_notification"
              to="/app/notifications/list"
            >
              {"View Notifications"}{" "}
            </NavLink>
          </Menu>
        ) : (
          ""
        )}

        {/* notification end */}
        <div className="user-detail" onClick={this.handleClick}>

          {/* Profile Icon */}
          <Chip
            style={{"cursor": "pointer"}}
            icon={<AccountCircleIcon />}
            label={Cookies.get("name")}
          />
          <i class="zmdi zmdi-caret-down zmdi-hc-fw align-middle"></i>
        </div>
        <Menu
          className="user-info"
          id="simple-menu"
          anchorEl={this.state.anchorEl}
          open={this.state.open}
          onClose={this.handleRequestClose}
          PaperProps={{
            style: {
              minWidth: 120,
              paddingTop: 0,
              paddingBottom: 0,
            },
          }}
        >
          {/* <MenuItem
            onClick={() => {
              this.handleRequestClose();
            }}
          >
            <NavLink to="/app/settings/password-update">
              <i class="zmdi zmdi-key zmdi-hc-fw mr-2"></i>
              <IntlMessages id="Password" />
            </NavLink>
          </MenuItem> */}
          <MenuItem
            onClick={() => {
              this.handleRequestClose();
              this.logoutConfirmation(true);
              //this.props.userSignOut();
            }}
          >
            <i className="zmdi zmdi-sign-in zmdi-hc-fw mr-2" />

            <IntlMessages id="popup.logout" />
          </MenuItem>
        </Menu>
      </div>
    );
  }
}

const mapStateToProps = ({ settings }) => {
  const { locale } = settings;
  return { locale };
};
export default withRouter(connect(mapStateToProps, { userSignOut })(UserInfo));
